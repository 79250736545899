import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderComponent } from '../order/order.component';
import { ChatComponent } from '../chat/chat.component';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwalService } from 'src/app/services/alert/swal.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit {

    coupon: any;
    orders: any = [];
    couponForm: FormGroup;
    unlimited: any = false;
    total_orders: any = 0;
    total: any = 0;
    list_supplier: any = []
    list_regalias: any = []
    supplier: any = ''
    regalia: any = ''
    listTypeCoupons: any = ['free_delivery', 'supplier_discount', 'second_round', 'unique', 'strike_first']

    type: any
    listSupplierProduct: any = []
    supplierProduct: any = ''
    discount_percentage: any = 0
    discount_max: any = 0
    days_without_order: any = 0
    // descuento sobre el total de la compra
    second_round_discount: any = 0
    second_round__mount_min: any = 0
    // BIN
    bankList: any = ''
    listBank: any = []
    bank: any = ''

    tinyConfs: any = {
        height: 250,
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount image textpattern noneditable help charmap  emoticons',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        importcss_append: true,
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: 'sliding',
        contextmenu: "link table",
        templates: [
            { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        ],
    }

    constructor(
        public api: ApiService,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        private http: HttpClient,
        public auth: AuthService,
        public formBuilder: FormBuilder,
        private SwalService: SwalService,
    ) {
        if (this.auth.countryCode == 'SV') {
            this.listTypeCoupons = ['free_delivery', 'supplier_discount', 'second_round', 'bin', 'unique', 'strike_first', 'all_store']
        }
        else if (this.auth.countryCode == 'HN') {
            this.listTypeCoupons = ['free_delivery', 'supplier_discount', 'second_round', 'bin', 'unique', 'strike_first', 'all_store', 'bin_price']
        }
        else if (this.auth.countryCode == 'RAF') {
            this.listTypeCoupons = ['free_delivery', 'supplier_discount', 'second_round', 'bin', 'unique', 'strike_first', 'all_store', 'bin_price']
        }
        else if (this.auth.countryCode == 'GT') {
            this.listTypeCoupons = ['free_delivery', 'supplier_discount', 'second_round', 'bin', 'unique', 'strike_first', 'all_store']
        }

        this.coupon = navParams.get('coupon');
        this.type = this.coupon.type

        if (this.coupon.regalia) {
            this.regalia = this.coupon.regalia
        }

        if (this.coupon.supplier) {
            this.supplier = this.coupon.supplier
        }

        // descuento por porcentaje de proveedor
        this.discount_percentage = this.coupon.discount_percentage
        if(this.coupon.hasOwnProperty('discount_max')  && this.coupon.discount_max > 0 ){
            this.discount_max = this.coupon.discount_max
        }
        if(this.coupon.hasOwnProperty('days_without_order')  && this.coupon.days_without_order > 0 ){
            this.days_without_order = this.coupon.days_without_order
        }
        // descuento sobre el total de la compra
        this.second_round_discount = this.coupon.second_round_discount
        this.second_round__mount_min = this.coupon.minimum

        this.unlimited = this.coupon.uses.unlimited;
        this.bank = this.coupon.bank

        // descripcion del terminos de uso del cupon
        if (this.coupon.info_txt == undefined) {
            this.coupon.info_txt = ''
        }

        this.couponForm = this.formBuilder.group({
            code: [this.coupon.code, [Validators.required]],
            available: [this.coupon.available, [Validators.required]],
            available_all_users: [this.coupon.available_all_users, [Validators.required]],
            type: [this.coupon.type, [Validators.required]],
            uses: [this.coupon.uses, [Validators.required]],
            supplier: [this.coupon.supplier],
            regalia: [this.coupon.regalia],
            bank: [''],
            discount_percentage: [''],
            discount_max: [''],
            days_without_order: [''],
            second_round_discount: [''],
            info_txt: [this.coupon.info_txt],
            minimum: [''],
            countryCode: this.auth.countryCode
        });


        this.getListOrders()
        this.getBankList()
    }

    async getListOrders() {
        await this.api.getRef('orders').ref
            // .where('coupon', '==', this.coupon.code)
            .where("coupon", "in", [this.coupon.code, this.coupon.code.toUpperCase(), this.coupon.code.toLowerCase()])
            .orderBy('added_date', 'desc')
            .get()
            .then(snapshots => {
                this.total_orders = snapshots.size;
                snapshots.forEach(element => {
                    let order = element.data();
                    order.date = order.added_date.toDate();
                    order.$key = element.id;
                    if (order.status !== 'canceled') {
                        if (order.total) {
                            this.total += Number(order.total);
                        }
                    }

                    this.orders.push(order);
                });
            })
    

        if (this.type == 'all_store') {
            this.total_orders = 0
            await this.api.getRef('orders').ref
                .where("all_store_code", "in", [this.coupon.code, this.coupon.code.toUpperCase(), this.coupon.code.toLowerCase()])
                .orderBy('added_date', 'desc')
                .get()
                .then(snapshots => {
                    snapshots.forEach(element => {
                        let order = element.data();
                        order.date = order.added_date.toDate();
                        order.$key = element.id;
                        if (order.status !== 'canceled') {
                            console.log(this.total)
                            if (order.total) {
                                this.total_orders = this.total_orders + 1
                                this.total += Number(order.total);
                            }
                        }
                        this.orders.push(order);
                    });
                })
            // this.orders = this.orders.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i)
            this.orders = await this.orders.filter((tag, index, array) => array.findIndex(t => t.number == tag.number) == index);
            this.orders.sort(function (a, b) {
                var dateA = a.added_date, dateB = b.added_date;
                return dateB - dateA;
            });
            await new Promise(r => setTimeout(r, 1000));

        }

        if (this.type == 'supplier_discount') {
            this.total_orders = 0
            await this.api.getRef('orders').ref
                .where("countryCode", "==", this.auth.countryCode)
                .where("usedCoupons", "array-contains-any", [this.coupon.code, this.coupon.code.toUpperCase(), this.coupon.code.toUpperCase()] )
                .where("supplier_discount_total", ">", 0)
                .get()
                .then(snapshots => {
                    snapshots.forEach(element => {
                        let order = element.data();
                        order.date = order.added_date.toDate();
                        order.$key = element.id;
                        if (order.status !== 'canceled') {
                            if (order.total) {
                                this.total_orders = this.total_orders + 1
                                this.total += Number(order.total);
                                this.orders.push(order);
                            }
                        }
                    }); 
                })
            // // this.orders = this.orders.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i)
            // this.orders = await this.orders.filter((tag, index, array) => array.findIndex(t => t.number == tag.number) == index);
            // this.orders.sort(function (a, b) {
            //     var dateA = a.added_date, dateB = b.added_date;
            //     return dateB - dateA;
            // });
            await new Promise(r => setTimeout(r, 1000));
        }
    }

    // obteniendo detalle de la cuenta de cada compra 
    ngOnInit() {
        this.getSupplier()
        this.getRegaliaStrikeFirst()
    }

    changeUnlimited(e) {
        if (e.detail.checked) {
            this.unlimited = true;
            this.coupon.uses = {
                unlimited: true
            };
        } else {
            this.unlimited = false;
            this.coupon.uses = 0;
        }
    }

    exportAccount() {
        let data = [];
        let accounts = [];
        let aux = [];
      
        // Obtener las cuentas asociadas a cada orden
        const accountPromises = this.orders.map(element => {
          return this.api.getDocument('accounts', element.account)
            .then((account: any) => {
              if (account) {
                accounts.push(account);
              }
            });
        });

        console.log(this.orders)
      
        // Esperar a que todas las promesas de obtención de cuentas se resuelvan
        Promise.all(accountPromises).then(() => {
      
          this.orders.forEach(element => {
            console.log(element, 'element');
            
            // Buscar la cuenta correspondiente en el arreglo de cuentas
            var day = (element.date.getDate() > 9) ? element.date.getDate() : ('0' + element.date.getDate());
            var month = (element.date.getMonth() + 1 > 9) ? (element.date.getMonth() + 1) : ('0' + (element.date.getMonth() + 1));
            var year = element.date.getFullYear();
            
            var formattedDate = day + '/' + month + '/' + year;
           
            
            const foundAccount = accounts.find(account => account.$key == element.account);
            data.push({
              "Dispositivo": `${element.systemOs != undefined ? element.systemOs : ''}`,
              "Orden #": element.number,
              "Nombre": foundAccount ? foundAccount.name : '',
              "Correo": foundAccount ? foundAccount.email : '',
              "Fecha": `${element.date != undefined ?  formattedDate: ''}`,
              "Estado": `${element.status != undefined ? element.status : ''}`,
              "Pago": `${element.total != undefined ? element.total : ''}`,
              "Descuento de proveedor": `${element.supplier_discount_total != undefined ? element.supplier_discount_total : ''}`,
              "Pago Delivery": `${element.total_delivery != undefined ? element.total_delivery : ''}`,
              "Pago Tip": `${element.tip != undefined ? element.tip : ''}`,
              "Tipo de pago": `${element.payment.type != undefined ? element.payment.type : ''}`,
              "Primeros Numeros" : `${element.payment.card != undefined && element.payment.card.cardNumber != undefined ? element.payment.card.cardNumber : ''}`,
              "Ultimos numeros": `${element.payment.card != undefined && element.payment.card.cardNumberLast != undefined ? element.payment.card.cardNumberLast : ''}`,
              "Tienda": `${element.storeCode != undefined ? element.storeCode : ''}`,
            });
          });
      
          var options = {
            title: `Reporte cupón ${this.coupon.code} ${this.auth.countryCode}`,
            headers: [
                "Dispositivo", 
                "Orden #",
                "Cliente",
                "Correo", 
                "Fecha",
                "Estado", 
                "Pago",
                "Descuento de proveedor", 
                "Pago Delivery", 
                "Pago Tip", 
                "Tipo de pago",
                "Primeros Numeros",
                "Ultimos numeros",
                "Tienda"
            ]
        };

         new Angular5Csv(data,`Reporte Cuenta de compras cupon ${this.coupon.code} ${this.auth.countryCode}`, options);

    });
      }
      
        updateCoupon() {
        
        this.loader().then(loader => {
            loader.present().then(async () => {
                let data = this.couponForm.value;

                console.log("data", data)
                
                data.created_at = new Date()
                if (this.unlimited) {
                    data.uses = { unlimited: true };
                }
                data.type = this.type

                if (data.code === '' || data.code == undefined) {
                    this.mensajeAlerta('error', 'Favor completar Nombre de Cupon');
                    loader.dismiss();
                    return;
                } else {
                    if (data.type != 'bin') {
                        data.code = data.code.toUpperCase()
                    }
                }

                if (data.type) {
                    switch (data.type) {
                        case "bin":
                            if (data.code === '' || data.code == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Campos');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "free_delivery":
                            if (data.uses === '' || data.uses == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Cantidad de Usos');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "second_round":
                            if (data.second_round_discount === '' || data.second_round_discount == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Monto Descuento');
                                loader.dismiss();
                                return
                            }
                            if (data.days_without_order === '' || data.days_without_order == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Cantidad de días sin Compras');
                                loader.dismiss();
                                return
                            }
                            if (data.minimum === '' || data.minimum == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Minimo Compra');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "share_code":
                            break;
                        case "supplier_discount":
                            if (data.discount_percentage === '' || data.discount_percentage == undefined) {
                                this.mensajeAlerta('error', 'Favor completar % Descuento');
                                loader.dismiss();
                                return
                            }
                            if (data.discount_max === '' || data.discount_max == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Cantidad Descuento Maxima');
                                loader.dismiss();
                                return
                            }
                            if (this.supplier === '' || this.supplier == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Proveedor');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "strike_first":
                            if (data.minimum === '' || data.minimum == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Minimo Compra');
                                loader.dismiss();
                                return
                            }
                            if (this.supplier === '' || this.supplier == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Regalia');
                                loader.dismiss();
                                return
                            }
                            break;
                        case "unique":
                            if (data.second_round_discount === '' || data.second_round_discount == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Monto Descuento');
                                loader.dismiss();
                                return
                            }
                            if (data.minimum === '' || data.minimum == undefined) {
                                this.mensajeAlerta('error', 'Favor completar Minimo Compra');
                                loader.dismiss();
                                return
                            }
                            break;
                    }
                }

                // Generate the history
                let historyAction = []
                delete data["history"]
                delete this.coupon["history"]
                this.api.addDocument(`coupons/${this.coupon.$key}/history`,{
                    created: new Date(),
                    email: this.auth.email,
                    action: "addedCoupon",
                    data: data,
                    oldData: this.coupon
                })

                // Validate old data and compare with new values
                for ( let[key, value] of Object.entries(this.coupon) ) {
                    if ( key != "created_at" ) {
                        if ( data.hasOwnProperty(key) && data[key] !== undefined && data[key] != value ) {
                            historyAction.push({
                                type: "Modification at " + key,
                                key:  key,
                                oldValue: this.coupon[key]
                            })
                        } 
                    }
                }

                // The history log maximium is 5

                if ( this.coupon.hasOwnProperty("history") ) {
                    data["history"] = this.coupon.history

                    if ( data["history"].length == 5 ) {
                        data["history"].shift()
                        data["history"].push({
                            updateAt: new Date(),
                            email: this.auth.email,
                            action: historyAction
                        })
                    } else {
                        data["history"].push({
                            updateAt: new Date(),
                            email: this.auth.email,
                            action: historyAction
                        })
                    }

                } else {
                    data["history"] = [{
                        updateAt: new Date(),
                        email: this.auth.email,
                        action: historyAction
                    }]
                }

                if(data.discount_max == 0){
                    delete data.discount_max
                    let resp = await this.api.deleteFieldsDocument(`coupons`, this.coupon.$key, ['discount_max'])
                }

                this.api.updateDocument('coupons', this.coupon.$key, data).then(data => {
                    loader.dismiss();
                    this.coupon = data;
                    this.SwalService.fire(
                        'Exito!',
                        'Cupón actualizado correctamente.',
                        'success'
                    )
                    loader.dismiss();
                    this.modalController.dismiss();
                })
           
                
            })
        })

    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    async selectOrder(order) {

        const modal = await this.modalController.create({
            component: OrderComponent,
            cssClass: 'addModal',
            componentProps: {
                order: order
            }
        });
        return await modal.present();

    }

    async selectChat(chat) {
        //console.log('selecionado chat');

        const modal = await this.modalController.create({
            component: ChatComponent,
            cssClass: 'addModal',
            componentProps: {
                conversation_key: chat.$key
            }
        });
        return await modal.present();

    }

    // se comenta - la operacion debe ser realizada desde monitor
    changeStatus(order, status) {
        //console.log("cambio de estado se desactiva")
        // Swal.fire({
        //     title: 'Esta seguro que deseas cambiar el estado?',
        //     text: "¡No podrás revertir esto!",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Si, estoy seguro'
        // }).then((result) => {
        //     if (result.value) {
        //         this.api.updateDocument(`orders`, order.$key, { status: status }).then(data => {
        //             order.status = status;
        //             let message;
        //             if (status == 'making') {
        //                 message = `Tu orden #${order.number} se está preparando 🔥`
        //             } else if (status == 'way') {
        //                 message = `Tu orden #${order.number} ya va de camino 🚚`
        //             } else if (status == 'delivered') {
        //                 message = `Tu orden #${order.number} ha sido entregada 🏡`
        //             }

        //             this.api.getAllDocuments(`accounts/${order.account.$key}/push_keys`).then(keys => {
        //                 if (keys) {
        //                     let push_keys = [];
        //                     keys.forEach(element => {
        //                         push_keys.push(element.push_key);
        //                     });

        //                     this.api.sendPush(`Actualización de estado`, message, push_keys);
        //                 }
        //             })

        //             Swal.fire(
        //                 'Estado actualizado!',
        //                 'Tu estado ha sido actualizado correctamente',
        //                 'success'
        //             )
        //         });
        //     }

        // })
    }
    // se remueve la llamada en el FrondEnd
    capture(order) {
        this.loader().then(loader => {
            loader.present().then(() => {

                this.SwalService.fire({
                    title: 'Esta seguro que deseas Aceptar la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=${order.number}&amount=${order.total}&type=1`;
                        let url;
                        if (this.auth.countryCode == 'SV') {
                            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'GT') {
                            url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
                        }

                        this.http.get(url).subscribe((response) => {
                            if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                                this.api.updateDocument('orders', order.$key, {
                                    'transaction_status': 'captured',
                                    'status': 'making'
                                }).then(data => {
                                    order.transaction_status = 'captured';
                                    this.SwalService.fire(
                                        'Transacción Capturada',
                                        'Captura de fondos realizar con exito',
                                        'success'
                                    )
                                })
                            } else {
                                this.SwalService.fire(
                                    'Error!',
                                    'No se pudo completar la captura de fondos, comnunicate con el equipo de barlleno',
                                    'error'
                                )
                            }
                            loader.dismiss();
                        }, err => {
                            loader.dismiss();
                        });
                    } else {
                        loader.dismiss();
                    }
                });
            })
        })
    }
    // se remueve la llamada en el FrondEnd
    revert(order) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
            })
        };

        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Revertir la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=${order.number}&amount=${order.total}&type=3`;
                        let url;
                        let id_transaction = this.orders.transaction_id;
                        if (this.auth.countryCode == 'CO') {
                            url = `https://production.wompi.co/v1/transactions/?${id_transaction}/void`;
                        } else if (this.auth.countryCode == 'SV') {
                            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'GT') {
                            url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
                        }

                        this.http.get(url)
                        url
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1 || response['data']['status'] == 'VOIDED') {
                                    this.api.updateDocument('orders', order.$key, {
                                        'transaction_status': 'Reverted',
                                        'status': 'canceled'

                                    }).then(data => {
                                        order.transaction_status = 'reverted';
                                        this.SwalService.fire(
                                            'Transacción Revertida',
                                            'Reversión de fondos realizar con exito',
                                            'success'
                                        )
                                    })
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo completar, comunicate con el equipo de Barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    }
                    else {
                        loader.dismiss();
                    }
                })
            })
        })
    }

    isActive(status, order_status) {

        if (order_status == 'pending') {
            if (status == 'pending') {
                return 'true';
            }
        }

        if (order_status == 'making') {
            if (status == 'pending' || status == 'making') {
                return 'true';
            }
        }

        if (order_status == 'way') {
            if (status == 'pending' || status == 'making' || status == 'way') {
                return 'true';
            }
        }

        if (order_status == 'delivered') {
            if (status == 'pending' || status == 'making' || status == 'way' || status == 'delivered') {
                return 'true';
            }
        }
    }

    cancelOrder(order) {
        this.SwalService.fire({
            title: `Esta seguro que deseas CANCELAR la orden ${order.number}?`,
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
            if (result.value) {
                //console.log(order);
                this.api.cancelOrder(order).then(data => {
                    order.status = 'canceled';
                })
            }

        })
    }

    onChangeType(evt) {
        //console.log(evt)
        this.type = evt
        this.getSupplierProduct()
    }

    onChangeSupplier(evt) {
        //console.log(evt)
        this.supplier = evt
    }

    onChangeBank(evt) {
        //console.log(evt)
        this.bank = evt
    }

    onChangeRegalia(evt) {
        //console.log(evt)
        this.regalia = evt
    }

    getSupplierProduct() {
        //console.log(this.supplier);

        this.listSupplierProduct = []
        this.api.getRef('bricks').ref
            .where('countryCode', '==', this.auth.countryCode)
            //.where("supplier", "==", this.supplier)
            .get()
            .then(snippets => {
                let element_size = snippets.size
                // //console.log(element_size)
                snippets.forEach(element => {
                    let supplier = element.data().name;
                    if (supplier) {
                        
                        this.listSupplierProduct.push(supplier)
                        
                    }
                });
                let uniq = [...new Set(this.listSupplierProduct)]

                this.listSupplierProduct = uniq.sort()

                // //console.log(this.listSupplierProduct)
                // //console.log(uniq.sort())

            })
    }

    getBankList() {
        //console.log(this.bankList);

        this.listBank = []
        this.api.getRef('configurations').ref
            .where('countryCode', '==', this.auth.countryCode)
            .get()
            .then(snippets => {
                let element_size = snippets.size
                //console.log("getBankList--> element_size")
                //console.log(element_size)
                if (element_size > 0) {
                    snippets.forEach(element => {
                        //console.log("element");
                        //console.log(element.data().bin_list);

                        let listElement = element.data().bin_list;
                        if (listElement) {
                            //console.log(typeof (listElement));
                            this.listBank = Object.keys(listElement).sort()
                            // //console.log(this.listBank)
                        }
                    });
                } else {
                    let obj = {
                        0: 'NA',
                    };
                    this.listBank = obj
                }
                //console.log(this.listBank)
            })
    }

    getSupplier() {
        this.list_supplier = []
        console.log( this.auth.countryCode)
        this.api.getRef('bricks').ref
            .where('countryCode', '==', this.auth.countryCode)
            // .where("type", "==", "brick")
            .where("type", "in", ["club", "brick"])
            .get()
            .then(snippets => {
                snippets.forEach(element => {
                    let supplier = element.data().supplier;
                    if (supplier) {
                        this.list_supplier.push(supplier)
                        
                    }
                });
                let uniq = [...new Set(this.list_supplier)]

                this.list_supplier = uniq.sort()

                console.log(this.list_supplier)

            })
    }

    getRegaliaStrikeFirst() {
        this.list_regalias = []
        this.api.getRef('configurations').ref
            .where('countryCode', '==', this.auth.countryCode)
            .get()
            .then(snippets => {
                let element_size = snippets.size
                //console.log("getBankList--> element_size")
                //console.log(element_size)
                if (element_size > 0) {
                    snippets.forEach(element => {
                        //console.log("list regalias");
                        //console.log(element.data().regalias);

                        let listElement = element.data().regalias;
                        if (listElement) {
                            listElement.forEach(item => {
                                //console.log(typeof (item));
                                item['texto'] = item.name + " " + item.presentation
                                this.list_regalias.push(item)
                            });
                        }
                    });
                } else {
                    let obj = {
                        0: {
                            name: 'NA',
                            presentation: 'NA'
                        },
                    };
                    this.list_regalias = obj
                }
                //console.log(this.list_regalias)
            })
    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'making'
})
export class MakingPipe implements PipeTransform {

  transform(icono: string, estado: string): string {

    if(estado == 'pending'){
      return `../../assets/icon/Iconos/${icono}.svg`;
    }else{
      return `../../assets/icon/Iconos/making.svg`
    }

  }

}

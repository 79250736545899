import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
	selector: 'app-add-reference-mixo',
	templateUrl: './add-reference-mixo.component.html',
	styleUrls: ['./add-reference-mixo.component.scss'],
})
export class AddReferenceMixoComponent implements OnInit {
	bricks: any = [];
	current_bricks: any = [];
	loading: any = true;
	lastRequest: any;
	searching: any = false;

	constructor(
		public api: ApiService,
		public modalController: ModalController,
		public auth: AuthService
	) { 
		this.loading = false
		this.getData()
	}
	getData() {
		//console.log("init getData")

		this.api.getRef('explore').ref
		.where('countryCode', '==', this.auth.countryCode)
		.where('type', '==', 'brick')
		.where('visible', '==', true)
		.orderBy('created_at', 'desc')
		.get()
		.then(snapshots => {
			this.bricks = [];
			snapshots.forEach(element => {
				//console.log(element.data());
				let brick = element.data();
				brick.$key = element.id;


				this.bricks.push(brick)

			});
			return this.bricks;
		});
	}

	ngOnInit() { }

	selectBrick(brick) {
        this.modalController.dismiss(
            brick
        )
    }

}

import { Component, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { algoliaConfig } from 'src/environments/environment.prod';

@Component({
    selector: 'app-add-reference-baner',
    templateUrl: './add-reference-baner.component.html',
    styleUrls: ['./add-reference-baner.component.scss'],
})
export class AddReferenceBanerComponent implements OnInit {
    sectionValue = "products"
    bricksResult = []

    constructor(
        private auth: AuthService,
        private api: ApiService
    ) { }

    ngOnInit() { }

	search(e) {

		let query = e.detail.value;
		if (query !== '') {
			const client = algoliasearch(algoliaConfig.appId, algoliaConfig.apiKey);
			const index = client.initIndex('bricks');

			let bricks = [];
			index.search(query, {
				hitsPerPage: 6
            }).then(({ hits }) => {
                    hits.forEach(async element => {
                        if (element['countryCode'] == this.auth.countryCode && element['storeKey'] == this.auth.storeKey) {
                            if (element['$key']) {
                                let mainValue = 0
                                let invetoryMain = 0
                                await this.api.getAllDocuments(`bricks/${element['$key']}/presentations`).then(snapshots => {
                                    if (snapshots) {
                                        snapshots.forEach(elementPresentacion => {
                                            let presentation = elementPresentacion
                                            mainValue = presentation['price']
                                            invetoryMain = presentation['inventory']
                                            if (presentation['main'] == true) {
                                                mainValue = presentation['price']
                                                invetoryMain = presentation['inventory']
                                                element['price'] = mainValue
                                                element['inventory'] = invetoryMain
                                            }
                                        });
                                    }
                                })
                            }
                            bricks.push(element)
                        }
                        if (element['countryCode'] == this.auth.countryCode) {

                            if (element['$key']) {
                                let mainValue = 0
                                let invetoryMain = 0
                                await this.api.getAllDocuments(`bricks/${element['$key']}/presentations`).then(snapshots => {
                                    if (snapshots) {
                                        snapshots.forEach(elementPresentacion => {
                                            let presentation = elementPresentacion
                                            mainValue = presentation['price']
                                            invetoryMain = presentation['inventory']
                                            if (presentation['main'] == true) {
                                                mainValue = presentation['price']
                                                invetoryMain = presentation['inventory']
                                                element['price'] = mainValue
                                                element['inventory'] = invetoryMain
                                            }
                                        });
                                    }
                                })
                            }
                            console.log(element)
                            bricks.push(element)
                        }
					});
					this.bricksResult = bricks;
				});
		} else {
			this.bricksResult = [];
		}
	}
}

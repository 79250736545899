export const RoleThreeDO = [
    {
        title: 'Monitor V2',
        url: '/index/monitor-v2',
        base: '',
        icon: 'notifications',
        alert: false
    },
    {
        title: 'Chats',
        url: '/index/chats',
        base: '',
        icon: 'chatbubbles',
        alert: false
    },
    {
        title: 'Dashboard',
        url: '/index/section/dashboard',
        base: '',
        icon: 'home',
        alert: false
    },
    {
        title: 'Buscador Producto',
        url: '/index/search-brick',
        base: '',
        icon: 'search-outline',
        alert: false
    },
    {
        title: 'Vinos',
        url: '/index/section/wines',
        base: '',
        icon: 'wine',
        customIcon: true,
        alert: false
    },
    {
        title: 'Destilados',
        url: '/index/section/liquors',
        base: '',
        icon: 'liquor',
        customIcon: true,
        alert: false
    },
    {
        title: 'Cervezas',
        url: '/index/section/beers',
        base: '',
        icon: 'beer',
        customIcon: true,
        alert: false
    },
    {
        title: 'No alcoholicas',
        url: '/index/section/non-alcoholic',
        base: '',
        icon: 'non-alcoholic',
        customIcon: true,
        alert: false
    },
    {
        title: 'Mixology',
        url: '/index/explore-section',
        base: '',
        icon: 'apps',
        alert: false
    },
    {
        title: 'Market',
        url: '/index/section/toeat',
        base: '',
        icon: 'toeat',
        customIcon: true,
        alert: false
    },
    {
        title: 'Para Fumar',
        url: '/index/section/digestic',
        base: '',
        icon: 'cigarrete',
        customIcon: true,
        alert: false
    },
    {
        title: 'Club Barlleno',
        url: '/index/section/club',
        base: '',
        icon: 'basket',
        alert: false
    },
    // {
    //     title: 'Reportes',
    //     url: '/index/reports-v3',
    //     base: '',
    //     // url: this.auth.countryCode === 'DO'  ?  '/reports-v2'  :  '/reports-v3',
    //     icon: 'newspaper-outline',
    //     alert: false
    // },
]
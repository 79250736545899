import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
	selector: 'app-add-brick-old',
	templateUrl: './add-brick-old.component.html',
	styleUrls: ['./add-brick-old.component.scss'],
})
export class AddBrickOldComponent implements OnInit {

	brickForm: FormGroup;
	brick_key: any;
	brick: any;
	brick_image: any = false;
	presentationName: any;
	presentationInventario: any;
	presentations: any = [];
	category: any;
	type: any = 'brick';
	imageBrick: any;
	// paso de parametros del partner
	partner: any
	supplier_partner: any

	constructor(
		public api: ApiService,
		public formBuilder: FormBuilder,
		public loadingCtrl: LoadingController,
		public navParams: NavParams,
		public modalController: ModalController,
		public auth: AuthService,
        private SwalService: SwalService

	) {
		let category = navParams.get('category');
		this.partner = navParams.get('partner');
		this.supplier_partner = navParams.get('supplier_partner');

		if (navParams.get('type')) {
			this.type = navParams.get('type');
		}
		let type = 'brick';
		if (category.club) {
			type = 'club'
		}

		let name = navParams.get('name');
		this.brickForm = this.formBuilder.group({
			name: [name, [Validators.required]],
			description: ['', [Validators.required]],
			visible: [true, [Validators.required]],
			category: [category.$key, [Validators.required]],
			type: [type, [Validators.required]],
			image_temp: [''],
			system_code: [''],
			supplier: [''],
			bar_code: [''],
			position: [0],
			countryCode: [this.auth.countryCode],
		});
        this.removeImages()
	}

	ngOnInit() { }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    uploadedImages(file) {
        this.brick_image = {
            name: file.file.name,
            src: file.src
        };
    }

    removeImages() {
        this.brick_image = false;
    }

    addBrick() {
        if (this.type == 'explore') {
            this.loader().then(loader => {
                loader.present().then(() => {
                    this.api.addExplore(this.brickForm.value, this.presentations, this.brick_image.src).then(data => {
                        loader.dismiss();
                        this.SwalService.fire('¡Listo!', 'Tu producto ha sido agregado', 'success');
                        this.modalController.dismiss();
                    });
                })
            })
        } else {
            this.loader().then(loader => {
                loader.present().then(() => {
                    let brickData = this.brickForm.value;
                // validaciones de datos
                if (brickData['name'] == '' || brickData['name'] == undefined) {
                    this.mensajeAlerta('error', 'Favor completar Nombre de Producto');
                    loader.dismiss();
                    return;
                }
                if (this.brick_image.src == '' || this.brick_image.src == undefined) {
                    if(this.auth.role != '0'){
                        this.mensajeAlerta('error', 'Favor completar Imagen de Producto');
                        loader.dismiss();
                        return;
                    }else {
                      //console.log("usuario puede subir bricks sin imagenes")
                    }
                }
                if (!(this.presentations.length > 0)) {
                    this.mensajeAlerta('error', 'Favor completar Presentaciones del Producto');
                    loader.dismiss();
                    return;
                } else {
                    const flagBoolean = this.presentations.map(item => {
                        if (item.price) return item.price.includes(',')
                        else return false
                    })
                    //console.log("flagBoolean ==>", flagBoolean)
                    if (flagBoolean.includes(true)) {
                        this.mensajeAlerta('error', 'Precio no valido, reemplazar (,) por (.)');
                        loader.dismiss();
                        return
                    }
                }
                
                // fin validaciones de datos

                    if (brickData.image_temp) {
                        brickData['image'] = {};
                        brickData['image']['downloadURL'] = brickData.image_temp;
                    }
                    delete brickData.image_temp;
                    //console.log(brickData);
                    if(this.partner){
                        brickData['partner'] = this.partner
                        brickData['supplier_partner'] = this.supplier_partner
                    }
                    if (!brickData.imagen_carrusel) {
                        brickData['imagen_carrusel'] = { 'downloadURL': [''] }
                    }
                    this.api.addBrick(brickData, this.presentations, this.brick_image.src).then(data => {
                        loader.dismiss();
                        this.SwalService.fire('¡Listo!', 'Tu producto ha sido agregado', 'success');
                        this.modalController.dismiss();
                    });
                })
            })
        }
    }


    changeMain(presentation, $event) {
        this.presentations.forEach(element => {
            element.main = false;
        });
        presentation.main = true;

    }

    changePrice(presentation, $event) {
        //console.log("changePrice", presentation)
        const { price } = presentation
        // if (price.includes(',')) {
            // this.mensajeAlerta('error', 'Caracter no valido, utilizar Punto (.)');
            // return;
        // }
    }
    
    addPresentation() {
        this.presentations.push({
            presentation: this.presentationName,
            inventory: "0",
            price: 0,
        });
        this.presentationName = '';
    }

    removePresentation(presentation) {
        for (let index = 0; index < this.presentations.length; index++) {
            const element = this.presentations[index];
            if (element.presentation == presentation.presentation) {
                this.presentations.splice(index, 1)
            }
        }

    }
    
    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }
}

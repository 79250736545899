import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { ClipboardService } from 'ngx-clipboard';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UploadService } from 'src/app/services/uploads/upload.service';
import { SwalService } from 'src/app/services/alert/swal.service';
import { FunctionsService } from 'src/app/services/functions/functions.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
	selector: 'app-add-account-access',
	templateUrl: './add-account-access.component.html',
	styleUrls: ['./add-account-access.component.scss'],
})
export class AddAccountAccessComponent implements OnInit {
	users: any = [];
	usersAux: any = [];
	p: number = 1;

	total_users: any = 0;
	total_month: any = 0;
	total_last_month: any = 0;

	loading: any = true;

	checkboxDate: any = false
	customStarDate: any;
	customEndDate: any;
	usersRange: any = [];

	search = '';
	// para habilitar el buscador
	setInit: any = false
	// recuperando la informacion de los seleccionables desde base
	role_system: any = []
	store_system: any = []
	role_select: any = ''
	store_select: any = ''

	constructor(
		public api: ApiService,
		private uploadService: UploadService,
		public formBuilder: FormBuilder,
		public loadingCtrl: LoadingController,
		public navParams: NavParams,
		public auth: AuthService,
		private http: HttpClient,
		public modalController: ModalController,
		private SwalService: SwalService,
		private functionsService: FunctionsService,
		private _clipboardService: ClipboardService
	) {
		// console.log("ingreso a modal de configuracion acceso a nuevos usuarios")
	}

	async ngOnInit() {
		this.getRoleCatalogue()
		this.getStoreCatalogue()
	}

	getRoleCatalogue() {
		this.api.getRef('catalogue').ref
			.where('type', '==', 'role')
			.orderBy('name', 'asc')
			.get()
			.then(data => {
				data.forEach(element => {
					if (element) {
						let unit = element.data();
						unit.$key = element.id;
						this.role_system.push(unit);
					}
				});
			})
	}

	getStoreCatalogue() {
		this.api.getRef('stores').ref
			.where('countryCode', '==', this.auth.countryCode)
			.orderBy('name', 'asc')
			.get()
			.then(data => {
				data.forEach(element => {
					if (element) {
						let unit = element.data();
						unit.$key = element.id;
						this.store_system.push(unit);
					}
				});
			})
	}

	initProcess() {
		this.setInit = true
		this.getAccounts();
	}

	async addUser(user) {
		// console.log("this.role_system = ",this.role_system, "role_select = ",this.role_select)
		 //console.log("this.store_system = ",this.store_system, "store_select = ",this.store_select)
		// return
		 console.log("user = ", user)
		let countryPath = this.auth.countryCode
		if (this.auth.countryCode == 'SV') {
			countryPath = 'general'
		}

		await this.api.getDocument('configurations', countryPath).then(async data => {
			if (data) {
				let flag_add = true
				// console.log("data configurationsCoutryCode = ", data)
				for (let index = 0; index < data['users'].length; index++) {
					let element = data['users'][index];
					// console.log("element = ", element, element.email, user.email)
					if (element.email == user.email) {
						flag_add = false
						// console.log("element.email = ", element.email, "user.email = ", user.email)
						break
					} else {
						// console.log("no exite correo en accounts")
					}
				}
				if (flag_add) {
					let users = data['users']
					users.push({ email: user.email, 'role': this.role_select })
					// console.log("users = ", users)
					// console.log("data = ", data)
					// moviendo hacia un proceso en batch para creacion del usuario en el pais y registro de las credenciales en users-stores
					let resp = await this.api.addAuthorizationUser(countryPath, this.auth.countryCode, this.store_select, data, user.email, user.$key, this.role_select)
					// console.log("addAuthorizationUser resp", resp)
					this.mensajeAlerta('success', `Exito, ${user.email} ha sido agregado`);
					this.modalController.dismiss({ 'status': 'new_users' })
				}
			}
		})
	}

	getAccounts() {
		// return
		this.total_month = 0
		this.total_last_month = 0
		this.usersAux = []

		this.api.getRef('accounts').ref
			.orderBy('created_at', 'desc')
			.where('current_country', '==', this.auth.countryCode)
			.limit(20)
			.get()
			.then((snapshots) => {
				this.loading = true;
				this.total_users = snapshots.size;
				//console.log("total_users:", this.total_users);

				// Quantity of users in month
				let start_date1 = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
				let end_date1 = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
				let start_date2 = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
				let end_date2 = new Date(new Date().getFullYear(), new Date().getMonth(), 0);

				this.total_month = 0
				this.total_last_month = 0
				this.usersAux = []

				//console.log(snapshots.docs.length)
				for (let i = 0; i < snapshots.size; i++) {
					let doc = snapshots.docs[i]
					let user = doc.data();
					user.$key = doc.id;
					user.created_at = user.created_at.toDate();

					if (user.created_at >= start_date1 && user.created_at <= end_date1) {
						this.total_month++
					}

					if (user.created_at >= start_date2 && user.created_at <= end_date2) {
						this.total_last_month++
					}

					user.index = i
					let obj = {
						'index': user.index,
						'created_at': user.created_at,
						'$key': user.$key,
						'name': user.name,
						'lastname': user.lastname,
						'current_country': user.current_country,
						'email': user.email,
					}

					this.usersAux.push(obj)
				}

				this.filterAccounts()
			})
	}

	getAccountsEmail(email) {
		// return
		this.total_month = 0
		this.total_last_month = 0
		this.usersAux = []

		this.api.getRef('accounts').ref
			.orderBy('created_at', 'desc')
			// .where('current_country', '==', this.auth.countryCode)
			.where('email', '==', email)
			.limit(20)
			.get()
			.then((snapshots) => {
				this.loading = true;
				this.total_users = snapshots.size;
				//console.log("total_users:", this.total_users);

				// Quantity of users in month
				let start_date1 = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
				let end_date1 = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
				let start_date2 = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
				let end_date2 = new Date(new Date().getFullYear(), new Date().getMonth(), 0);

				this.total_month = 0
				this.total_last_month = 0
				this.usersAux = []

				//console.log(snapshots.docs.length)
				for (let i = 0; i < snapshots.size; i++) {
					let doc = snapshots.docs[i]
					let user = doc.data();
					user.$key = doc.id;
					user.created_at = user.created_at.toDate();

					if (user.created_at >= start_date1 && user.created_at <= end_date1) {
						this.total_month++
					}

					if (user.created_at >= start_date2 && user.created_at <= end_date2) {
						this.total_last_month++
					}

					user.index = i
					let obj = {
						'index': user.index,
						'created_at': user.created_at,
						'$key': user.$key,
						'name': user.name,
						'lastname': user.lastname,
						'current_country': user.current_country,
						'email': user.email,
					}

					this.usersAux.push(obj)
				}

				this.filterAccounts()
			})
	}

	filterAccounts(flag_email = false) {
		this.loading = true;
		if (flag_email == true) {
			// console.log("getAccountsEmail this.search = ", this.search)
			this.getAccountsEmail(this.search.trim())
		} else {
			const searchParams: any[] = ["name", "lastname", "email"];

			// Dates range search
			this.usersRange = []
			if (this.checkboxDate && this.customStarDate && this.customEndDate) {
				let start_date = new Date(this.customStarDate)
				let end_date = new Date(this.customEndDate)

				if (start_date.getTime() < end_date.getTime()) {
					start_date.setHours(0, 0, 0, 0)
					end_date.setHours(23, 59, 59, 59)

					this.usersAux.forEach(user => {
						if (user.created_at.getTime() >= start_date.getTime() && user.created_at.getTime() <= end_date.getTime()) {
							this.usersRange.push(user)
						}
					});

					this.users = this.functionsService.filterArray(this.search.trim(), this.usersRange, searchParams)
					this.loading = false;
					return
				}
			}
			// Normal search
			this.users = this.functionsService.filterArray(this.search.trim(), this.usersAux, searchParams)
		}

		// console.log("this.users = ", this.users)
		this.loading = false;
	}

	mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
		Swal.fire({
			position: 'top-end',
			icon: icono,
			title: mensaje,
			showConfirmButton: false,
			timer: 3500,
			toast: true
		});
	}
}

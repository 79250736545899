import { EventEmitter, Injectable } from '@angular/core';
import { DataOrderClient } from '../../interfaces/orderPoints';
import { DatesReports } from '../../interfaces/DatesReports.interface';

@Injectable({
  providedIn: 'root'
})
export class TransfTimeOrdersService {

  constructor() { }

  dataTimeOrders   = new EventEmitter<DataOrderClient[]>();
  fechasEncuentas  = new EventEmitter<DatesReports>();
  fechasBarllenoON = new EventEmitter<DatesReports>();
  fechasReporte  = new EventEmitter<DatesReports>();
  isLoading = new EventEmitter<Boolean>();
}

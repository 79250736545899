import { TransfTimeOrdersService } from './../../services/transf-data/transf-time-orders.service';
import { DataOrderClient } from './../../interfaces/orderPoints';
import { Component, OnInit } from '@angular/core';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-orders-time',
  templateUrl: './orders-time.component.html',
  styleUrls: ['./orders-time.component.scss'],
})
export class OrdersTimeComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private transfTimeOrdersService: TransfTimeOrdersService
  ) { }

  ngOnInit(): void {
    this.getOrders();
    this.listenLoad();
  }

  ordersByTime: DataOrderClient[] = [];
  isLoading: boolean = false;

  listenLoad(){
    this.transfTimeOrdersService.isLoading.subscribe(
      (response: boolean) => this.isLoading = response
    );
  }

  getOrders() {
    this.isLoading = true;
    this.ordersByTime = [];
    this.transfTimeOrdersService.dataTimeOrders.subscribe(
      (response: DataOrderClient[]) => {
        this.ordersByTime = response;
        this.isLoading = false;
      }
    );
  }

  getReportOrdersByTime() {
    const reportOrdersByTime = [];

    this.ordersByTime.forEach(
      order => {
        reportOrdersByTime.push({
          "No. Order": order.dataOrder.number,
          "Cliente": `${order.client.name} ${order.client.lastname}`,
          "Estado": order.dataOrder.status,
          "Fecha registro": order.dataOrder.status_time.pending,
          "Preparación": order.dataOrder.status_time.making,
          "Listo en tienda": order.dataOrder.status_time.way,
          "En ruta": order.dataOrder.status_time.pickup,
          "Entregada": order.dataOrder.status_time.delivered,
          "Registro -> Preparación": order.dataOrder.makingMin,
          "Preparación -> Listo en tienda": order.dataOrder.wayMin,
          "Preparación -> Listo en ruta": order.dataOrder.pickupMin,
          "Listo -> Entregado": order.dataOrder.deliverymin,
          "Tiempo total": order.dataOrder.totalTimeOrder
        });
      }
    );

    var options = {
      title: 'Reporte tiempos de rodenes Barlleno',
      headers: [
        "No. Order",
        "Cliente",
        "Estado",
        "Fecha registro",
        "Preparación",
        "Listo en tienda",
        "En ruta",
        "Entregada",
        "Registro -> Preparación",
        "Preparación -> Listo en tienda",
        "Preparación -> Listo en ruta",
        "Listo -> Entregado",
        "Tiempo total"
      ]
    };

    new Angular5Csv(
      reportOrdersByTime,
      `Reporte tiempos de ordenes Barlleno ${this.auth.countryCode} ${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
      options
    );
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

 // Pipes Personalizados
import { ProductosPipe } from './productos.pipe';
import { IconosTarjetasPipe } from './iconos-tarjetas.pipe';
import { PendingPipe } from './pending.pipe';
import { MakingPipe } from './making.pipe';
import { DeliveredPipe } from './delivered.pipe';
import { WayPipe } from './way.pipe';
import { PointsClubPipe } from './points-club.pipe';
import { StorePipe } from './store.pipe';
import { EmialPipe } from './email.pipe';

@NgModule({
  declarations: [
    ProductosPipe,
    IconosTarjetasPipe,
    PendingPipe,
    MakingPipe,
    DeliveredPipe,
    WayPipe,
    PointsClubPipe,
    StorePipe,
    EmialPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    ProductosPipe,
    IconosTarjetasPipe,
    PendingPipe,
    MakingPipe,
    DeliveredPipe,
    WayPipe,
    PointsClubPipe,
    StorePipe,
    EmialPipe
  ]
})
export class PipesModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHistoryService, WindowService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-app-bar',
    templateUrl: './app-bar.component.html',
    styleUrls: ['./app-bar.component.scss'],
})
export class AppBarComponent implements OnInit {
    account: any = false;
    unreadNotificationsCount: any = 0;
    unreadMessagesCount: any;

    constructor(
        private router: Router,
        public auth: AuthService,
        private routerHistoryService: RouterHistoryService,
        private windowService: WindowService
    ) { }

    ngOnInit() { }

    
    getUnreadMessagesCount() {
        if (this.unreadMessagesCount) {
            if (this.unreadMessagesCount > 0) {
                return this.unreadMessagesCount;
            }
        }
        return null;
    }
    logout() {
        this.auth.logOut();
    }

    onClick($event: MouseEvent): void {
        $event.preventDefault();
        this.windowService.nativeWindow.history.back();
    }

}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {

    partner: any;
    partnerForm: FormGroup;

    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        public auth: AuthService,
        private SwalService: SwalService
    ) {
        this.partner = navParams.get('partner');

        this.partnerForm = this.formBuilder.group({
            name: [this.partner.name, [Validators.required]],
            free_delivery: [this.partner.free_delivery],
            countryCode: [this.auth.countryCode],
            deliveries: [this.partner.deliveries],
        });

    }

    ngOnInit() { }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    updatePartner() {
        this.loader().then(loader => {
            loader.present().then(() => {

                this.api.updateDocument('partners', this.partner.$key, this.partnerForm.value).then(data => {
                    loader.dismiss();
                    this.SwalService.fire('¡Listo!', 'Tu socio ha sido actualizado.', 'success');
                    this.modalController.dismiss();
                });
            })
        })
    }
}

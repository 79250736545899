import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-active-accounts',
  templateUrl: './table-active-accounts.component.html',
  styleUrls: ['./table-active-accounts.component.scss'],
})
export class TableActiveAccountsComponent implements OnInit {
	@Input() accounts:  any

  constructor() { }

  ngOnInit() {}

}

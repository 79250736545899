import { Component, Input, OnInit } from "@angular/core";
import { FunctionsService } from "src/app/services/functions/functions.service";

@Component({
	selector: "app-table-bricks",
	templateUrl: "./table-bricks.component.html",
	styleUrls: ["./table-bricks.component.scss"],
})
export class TableBricksComponent implements OnInit {
	searchBrick = "";
	bricks: any;
	@Input() bricksAux: any = [];
	@Input() currency: any = "";
	constructor(private functionsService: FunctionsService) { }

	ngOnInit() {
		this.bricks = this.bricksAux;
	}

	filterProducts() {
		this.bricksAux = this.functionsService.filterArray(
			this.searchBrick,
			this.bricks,
			["name"]
		);
	}
}

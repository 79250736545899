import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-time-orders',
  templateUrl: './table-time-orders.component.html',
  styleUrls: ['./table-time-orders.component.scss'],
})
export class TableTimeOrdersComponent implements OnInit {
	@Input() orders:  any

  constructor() { }

  ngOnInit() {}

}

import { Pipe, PipeTransform } from '@angular/core';
import { BrickElement } from '../interfaces/orderPoints';

@Pipe({
  name: 'pointsClub'
})
export class PointsClubPipe implements PipeTransform {

  transform(bricks: BrickElement[]): number {
    let totalPoints: number = 0;

    bricks.forEach(brick => {
      if(brick.type == 'club'){
        totalPoints += brick.subtotal_points;
      }   
    })

    return totalPoints;
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { DataOrderClient } from 'src/app/interfaces/orderPoints';
import Swal from 'sweetalert2';
import { AuthService } from '../auth/auth.service';
import { ReportsService } from '../reports/reports.service';
import { SapService } from '../sap/sap.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OrdersService {
    baseHeaders: any = {}
    productsEvents: any = []
    eventSelect: any
    listEvents: any = []
    loaderEvent: boolean = true

    constructor(
        private firebase: AngularFirestore,
        private authService: AuthService,
        private reportService: ReportsService,
        private sap: SapService
    ) { }

    private ordersRef = this.firebase.collection('orders');
    private countryCode = localStorage.getItem('countryCode');

    async getScoreAppService(storeKey = null): Promise<DataOrderClient[]> {
        const orders: any = [];
        let dataOrderClient: DataOrderClient[] = [];

        if (storeKey != null) {
            await this.firebase.collection('orders', ref => ref
                .where('serviceExperience', '>=', 0)
                .where('storeKey', '==', storeKey)
                .where('countryCode', '==', this.authService.countryCode)
            ).get().toPromise().then(
                (response) => response.forEach(
                    snap => orders.push(snap.data())
                )
            );
        } else {
            await this.firebase.collection('orders', ref => ref
                .where('serviceExperience', '>=', 0)
                .where('countryCode', '==', this.authService.countryCode)
            ).get().toPromise().then(
                (response) => response.forEach(
                    snap => orders.push(snap.data())
                )
            );
        }

        dataOrderClient = await this.reportService.getDataClient(orders)

        orders.forEach(order => {
            order.schedule_date = order.schedule_date.toDate();
            order.schedule_time = order.schedule_time.toDate();
            order.appExperience = Number(order.appExperience);
            order.serviceExperience = Number(order.serviceExperience);
        });

        return dataOrderClient;
    }

    async getOrderById(idOrder: number) {
        let order: any;
        await this.firebase.collection('orders', ref => ref
            .where('number', '==', idOrder)
        ).get().toPromise().then(
            snap => snap.forEach(
                snapChild => order = snapChild.data()
            )
        );
        //console.log("OrdersService = ", order)
        return order;
    }

    capturePayment = async (token: string, account: string, countryCode: string, number: number) => {
        console.log("capturePayment")
        console.log("token = ", token)
        this.baseHeaders = {
            'Access-Control-Allow-Origin': '*',
            'application': 'x-www-form-urlencoded',
            'Content-type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
            'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
            'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
        }
        // let response = await fetch(`$http://localhost:3000/service/orders/update/CapturePayment`, {
        let response = await fetch(`http://localhost:3000/service/orders/update/capture`, {
            method: 'POST',
            body: JSON.stringify({ "token": token, data: { account: account, countryCode: countryCode, number: number } }),
            headers: this.baseHeaders
        });
        console.log("response = ", response)
        return response.json()
    }



    updateOrder(order: any, factura: string) {
        if (factura.length != 6) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Factura debe tener 6 digitos',
                showConfirmButton: false,
                timer: 3500,
                toast: true
            })
            return false;
        }

        this.ordersRef.doc(order).update({
            idBill: factura
        }).then(
            response => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Factura asignada con éxito',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        )
    }


    getListEvents() {
        let header = new HttpHeaders({
            'Access-Control-Allow-Origin': 'origin',
            'Content-type': 'application/json',
            'Authorization': 'Basic ' + this.authService.token,
            'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE',
        })
        this.sap.get("/accounts/get/events", header).subscribe({
            next: (response) => {
                this.listEvents = response.data
                this.eventSelect = response.data[0]
                this.getProductsEvents()
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

    // Set zone and event of one event
    getProductsEvents() {
        let header = new HttpHeaders({
            'Access-Control-Allow-Origin': 'origin',
            'Content-type': 'application/json',
            'Authorization': 'Basic ' + this.authService.token,
            'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE',
        })
        let body = {
            event: this.eventSelect.key,
            zone: this.eventSelect.default_zone
        }
        this.sap.post("/service/bricks/get/events", body, header).subscribe({
            next: (response) => {
                this.productsEvents = response.data
                this.loaderEvent = false
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

}

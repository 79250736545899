import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from 'angularfire2/firestore';
import { AuthService } from '../auth/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/storage'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import { WhereFilterOp, OrderByDirection } from "@firebase/firestore-types";



@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        public db: AngularFirestore,
        public auth: AuthService,
        public angularfire: AngularFireDatabase,
        private http: HttpClient,
    ) {
        console.log(this.auth.sector)
     }

    async batchPresentation(brick, newVisibility: boolean): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let batch = this.db.firestore.batch();
            let snippets;
    
            try {
                
                if (this.auth.countryCode == 'DO' && newVisibility == true) {
                    snippets = await this.getRef(`bricks/${brick.hasOwnProperty('brick') ? brick.brick : brick.$key}/presentations/`).ref.get();
                } else if (this.auth.countryCode != 'DO' && newVisibility == true) {
                    snippets = await this.getRef(`bricks/${brick.hasOwnProperty('brick') ? brick.brick : brick.$key}/presentations/`)
                        .ref.where('sincSAP', "==", true).get();
                } else {
                    snippets = await this.getRef(`bricks/${brick.hasOwnProperty('brick') ? brick.brick : brick.$key}/presentations/`).ref.get();
                }
                
                const element_size = snippets.size;
                let presentations = [];
                if (element_size > 0) {
                    for (const element of snippets.docs) {
                        const data = element.data();
                        data.visible = Number(data.inventory) > 0 && newVisibility == true ? true : false;
                        presentations.push(data);
                        batch.set(this.db.firestore.collection(`bricks/${brick.hasOwnProperty('brick') ? brick.brick : brick.$key}/presentations/`).doc(element.id), data);
                    }
                    
                }
                if (presentations.some((element) => element.visible == true)) {
                    resolve(true);
                } else {
                    resolve(false);
                }
    
                await batch.commit();
            } catch (error) {
                reject(error);
            }
        });
    }


    decrementInventory(bricks: any, sector: string, orderId:string){
        return new Promise(async (resolve, reject) => {
            let batch = this.db.firestore.batch();
            let zone = null
            let dataNotification = []

            for ( let i = 0; i < bricks.length; i++) {
                let docRef = this.db.firestore.collection(`bricks/${bricks[i].brick_key}/presentations/`).doc(bricks[i].$key);
                let doc =  await docRef.get();
                if ( doc.exists )  {
                    let data:any = doc.data();
                    if ( data.hasOwnProperty("inventaryZones") ) {
                        if ( zone == null ) {
                            zone = data.inventaryZones.find((element:any) => element.zone == sector)
                            zone = zone['key'] ? zone['key'] : ""
                        }
                        let updatedInventary = data.inventaryZones.map((element:any) => {
                            if ( element.zone == sector ) {
                                element.inventory = element.inventory - bricks[i].quantity
                                if ( element.inventory == 0 ) {
                                    dataNotification.push({
                                        brickKey: bricks[i].brick_key,
                                        presentationKey: bricks[i].$key,
                                        zone: sector
                                    })
                                }
                            }
                            return element;
                        });
                        batch.update(docRef, { inventaryZones: updatedInventary });
                    }
                }

            }
            if ( dataNotification.length > 0 ) {
                this.http.post('https://reports.barlleno.app/service/bricks/update/notifyinventory', {
                    bricks: dataNotification
                }).subscribe((data) => {
                    console.log(data)
                })
            }
            console.log({ storeZoneInventary: sector })
            this.updateDocument('orders', orderId, { storeZoneInventary: sector })
            batch.commit();
            resolve(true)
        })
    }
    


    async batchReferencesBrick(brick, newVisibility: boolean):Promise<void>{
        let batch = this.db.firestore.batch();


        const snippets = await this.getRef("bricks")
        .ref.where("brick", "==", brick.$key)
        .get();
        
        const element_size = snippets.size;
        if (element_size > 0) {
            for (const element of snippets.docs) {
                let data = element.data();
                data.visible= newVisibility;
                batch.set(this.db.firestore.collection('bricks').doc(element.id), data);
            }
        }
        
        await batch.commit();
    }


    getAllDocuments(collection: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collection)
                .get()
                .toPromise()
                .then((querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach(function (doc) {
                        var obj = JSON.parse(JSON.stringify(doc.data()));
                        obj.$key = doc.id
                        arr.push(obj);
                    });

                    if (arr.length > 0) {
                        resolve(arr);
                    } else {
                        resolve(null);
                    }


                })
                .catch((error: any) => {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        console.error(error);
                        reject(error);
                    }
                });
        });
    }

    getDocument(collection, documentId) {
        return new Promise((resolve, reject) => {
            this.db.collection(collection).doc(documentId)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    doc["$key"] = snapshot.id;
                    resolve(doc);
                }).catch((error: any) => {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        console.error(error);
                        reject(error);
                    }
                });
        })
        // return this.firestore.collection('songList').doc(songId);
    }


    getDocumentCategories(collection, documentId) {
        return new Promise((resolve, reject) => {
            this.db.collection(collection).doc(documentId)
                .get()
                .toPromise()
                .then((snapshot) => {
                    let doc = snapshot.data();
                    if(snapshot.exists){
                     doc["$key"] = snapshot.id;
                     resolve(doc);
                    }else{
                        resolve(null)
                        this.updateDocument("bricks", documentId, {delete: true, deleteDetails: {date: new Date(), user: this.auth.email}} )
                    }
    
                }).catch((error: any) => {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        reject(error);
                    }
                });
        })
        // return this.firestore.collection('songList').doc(songId);
    }

    deleteDocument(collectionName: string, docID: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .delete()
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    addDocument(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db.collection(collectionName).add(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        console.error(error);
                        reject(error);
                    }
                });
        });
    }

    updateDocument(collectionName: string, docID: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .update(dataObj)
                .then((obj: any) => {
                    resolve(obj);
                })
                .catch((error: any) => {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        console.error(error);
                        reject(error);
                    }
                });
        });
    }

    setDocument(collectionName: string, docID: string, dataObj: any) {
        return new Promise((resolve, reject) => {
            this.db
                .collection(collectionName)
                .doc(docID)
                .set(dataObj)
                .then((obj: any) => {
                    resolve(obj)
                })
                .catch((error: any) => {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                        reject(null);
                    } else {
                        console.error(error);
                        reject(error);
                    }
                })
        })
    }

    ///////////////////////////////
    /////////////CUSTOM API REQUESTS
    ///////////////////////////////

    public getCollection$ = (
		collection: string,
		// events: DocumentChangeType[],
		filters: { field: string; comparison: WhereFilterOp; value: any }[] = [],
		order: string | null = null,
		direction: OrderByDirection | undefined = undefined
	) => {
        return this.db.collection<any>(collection,(ref)=> {
            let query: CollectionReference | Query = ref;
            if (filters.length > 0) {
				filters.forEach((filter) => {
					query = query.where(filter.field, filter.comparison, filter.value);
				});
			}
            if (order !== null && direction !== undefined) {
				query = query.orderBy(order, direction);
			}
            return query
        }).valueChanges({ idField: '$key' })
        
	};

    getRef(collection) {
        return this.db.collection(collection);
    }


    uploadSectionImage(brick, image) {
        return new Promise((resolve, reject) => {
            // //console.log(brick);

            let storageRef = firebase.storage().ref();
            const filename = this.generateUUID();
            let imageRef = storageRef.child(`categories/${brick}/${filename}.png`);

            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    photoData['fullPath'] = data.metadata.fullPath;
                    photoData['size'] = data.metadata.size;
                    photoData['contentType'] = data.metadata.contentType;
                    photoData['md5Hash'] = data.metadata.md5Hash;
                    photoData['bucket'] = data.metadata.bucket;
                    photoData['updated'] = data.metadata.updated;
                    imageRef.getDownloadURL().then(data => {
                        // //console.log(data);

                        photoData['downloadURL'] = data;
                        resolve(photoData);
                    });
                })
        })
    }

    uploadSectionImageSlide(brick, image, pos) {
        return new Promise((resolve, reject) => {
            let storageRef = firebase.storage().ref();
            const filename = this.generateUUID();
            let imageRef = storageRef.child(`categories/${brick}/${filename}.png`);

            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    imageRef.getDownloadURL().then(data => {
                        let posImage = 'downloadURL' + pos
                        photoData[posImage] = data;
                        resolve(photoData);
                    });
                })
        })
    }
    
    uploadImage(path, image) {
        return new Promise((resolve, reject) => {
            let storageRef = firebase.storage().ref();
            let imageRef = storageRef.child(path);
            console.log( firebase.storage.StringFormat.DATA_URL)
            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    photoData['fullPath'] = data.metadata.fullPath;
                    photoData['size'] = data.metadata.size;
                    photoData['contentType'] = data.metadata.contentType;
                    photoData['md5Hash'] = data.metadata.md5Hash;
                    photoData['bucket'] = data.metadata.bucket;
                    photoData['updated'] = data.metadata.updated;
                    imageRef.getDownloadURL().then(data => {
                        photoData['downloadURL'] = data;
                        resolve(photoData);
                    });
                })
        })
    }

    uploadBrickImage(brick, image) {
        return new Promise((resolve, reject) => {
            let storageRef = firebase.storage().ref();
            const filename = this.generateUUID();
            let imageRef = storageRef.child(`categories/${brick}/${filename}.png`);

            imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                .then(data => {
                    let photoData = {};
                    photoData['fullPath'] = data.metadata.fullPath;
                    photoData['size'] = data.metadata.size;
                    photoData['contentType'] = data.metadata.contentType;
                    photoData['md5Hash'] = data.metadata.md5Hash;
                    photoData['bucket'] = data.metadata.bucket;
                    photoData['updated'] = data.metadata.updated;
                    imageRef.getDownloadURL().then(data => {

                        photoData['downloadURL'] = data;
                        resolve(photoData);
                    });
                })
        })
    }

    addBrick(brick_data, presentations, image,storeCopy?,categoryCopy?,multiStore?) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();

            let brick_key = this.db.createId();
            brick_data.$key = brick_key
            brick_data.created_at = new Date();
            brick_data.account_email_create = this.auth.email

            let bar_codeArray = []

            presentations.forEach(element => {
                let presentation_key = this.db.createId();
                let { bar_code } = element

                if (this.auth.countryCode == "DO") {
                  if (!element.hasOwnProperty("inventory_ZONE1")) {
                    element.inventory_ZONE1 = 0;
                  }
                  if (!element.hasOwnProperty("inventory_ZONE2")) {
                    element.inventory_ZONE2 = 0;
                  }

                  element.inventory = element.inventory_ZONE1 + element.inventory_ZONE2
                }

                if (bar_code != undefined && bar_code != '') {
                    bar_codeArray.push(bar_code)
                }

                batch.set(this.db.firestore.collection(`bricks/${brick_key}/presentations/`).doc(presentation_key),
                    element
                );
            });

            if (bar_codeArray.length > 0) {
                brick_data['bar_code'] = bar_codeArray
            }

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`bricks/${brick_data.category}/${brick_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        let arrayImage = []
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(dataResponse => {
                            // photoData['downloadURL'] = dataResponse;
                            brick_data.image = { 'downloadURL': '' }
                            brick_data.image['downloadURL'] = dataResponse;
                            brick_data.imagen_carrusel['downloadURL'][0] = dataResponse
                            if (brick_data.imagen_carrusel['downloadURL']) {
                                for (let i = 0; i < brick_data.imagen_carrusel['downloadURL'].length; i++) {
                                    if (brick_data.imagen_carrusel['downloadURL'][i] == '') {
                                        delete brick_data.imagen_carrusel['downloadURL'][i]
                                    } else {
                                        arrayImage.push(brick_data.imagen_carrusel['downloadURL'][i])
                                    }
                                }
                                brick_data.imagen_carrusel['downloadURL'] = arrayImage
                            }
                            batch.set(this.db.firestore.collection(`bricks`).doc(brick_key),
                                brick_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            }).then(data => {
                                if(multiStore && multiStore == true){
                                try {
                                   
                                    
                                    this.http.post('https://reports.barlleno.app/service/barlleno/bricks/createbrick', {
                                        brickOriginal:brick_data.$key,
                                        newCategory:categoryCopy,
                                        newStoreCode:storeCopy.storeCode,
                                        newStore:storeCopy.$key,
                    
                                    }).subscribe((data) => {
                                        console.log(data)
                                    })
                                } catch (error) {
                                    
                                }
                                }})


                        });
                    })

            } else {
                let image_temp = 'https://firebasestorage.googleapis.com/v0/b/barlleno.appspot.com/o/public%2FiconBotella2021.png?alt=media&token=3f057adb-1ae2-4fdc-b3ff-a9ef6ab94dd1'
                brick_data.image = { 'downloadURL': '' }
                brick_data.image['downloadURL'] = image_temp;
                brick_data.imagen_carrusel['downloadURL'][0] = image_temp
                delete brick_data.imagen_carrusel

                batch.set(this.db.firestore.collection(`bricks`).doc(brick_key),
                    brick_data
                );

    


                batch.commit().then(data => {
                    resolve(null);
                }).then(data => {
                    if(multiStore && multiStore == true){
                    try {

                        this.http.post('https://reports.barlleno.app/service/barlleno/bricks/createbrick', {
                            brickOriginal:brick_data.$key,
                            newCategory:categoryCopy,
                            newStoreCode:storeCopy.storeCode,
                            newStore:storeCopy.$key,
        
                        }).subscribe((data) => {
                            console.log(data)
                        })
                    } catch (error) {
                        
                    }
                    }}
                )
            }


         
            
        });
    }

    addExplore(brick_data, presentations, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let brick_key = this.db.createId();
            brick_data.created_at = new Date();

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`explore/${brick_data.category}/${brick_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                                brick_data
                            );
                            //console.log(brick_data);
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    addBlog(brick_data, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let brick_key = this.db.createId();
            brick_data.created_at = new Date();
            brick_data.$key = brick_key
            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`explore/${brick_data.category}/${brick_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                                brick_data
                            );
                            //console.log(brick_data);
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    updateBlog(brick_data, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let brick_key = brick_data.$key
            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`explore/${brick_data.category}/${brick_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.set(this.db.firestore.collection(`explore`).doc(brick_data.$key),
                                brick_data
                            );
                            //console.log(brick_data);
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })
            } else {
                batch.set(this.db.firestore.collection(`explore`).doc(brick_data.$key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    addPromotion(prom_data, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let prom_key = this.db.createId();
            prom_data.created_at = new Date();

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`promotions/${prom_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            prom_data.image = photoData;
                            batch.set(this.db.firestore.collection(`promotions`).doc(prom_key),
                                prom_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.set(this.db.firestore.collection(`promotions`).doc(prom_key),
                    prom_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    addClubItem(item_data, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let item_key = this.db.createId();
            item_data.created_at = new Date();

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`club-items/${item_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            item_data.image = photoData;
                            batch.set(this.db.firestore.collection(`club-items`).doc(item_key),
                                item_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.set(this.db.firestore.collection(`club-items`).doc(item_key),
                    item_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    addKit(kit_data, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();
            let kit_key = this.db.createId();
            kit_data.created_at = new Date();

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`kits/${kit_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            kit_data.image = photoData;
                            batch.set(this.db.firestore.collection(`kits`).doc(kit_key),
                                kit_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.set(this.db.firestore.collection(`kits`).doc(kit_key),
                    kit_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    updateBrick(brick_data, presentations, on_presentations, image, superbPresentations = [], superb_on_presentations = [], comercios_on_presentations = []) {
        return new Promise(async (resolve, reject) => {
            // //console.log("brick_data = ", brick_data, typeof brick_data, "delete = true" );
            if (brick_data['reference']) {
                delete brick_data['position']
                delete brick_data['reference']
            } else {
                delete brick_data['position']
            }

            if (brick_data['bar_code']) {
                delete brick_data['bar_code']
            }

            let batch = this.db.firestore.batch();
            delete brick_data["account_email_update_log"]

            
            
            
            this.addDocument(`bricks/${brick_data.$key}/history`, {
                date: new Date(),
                action: "modificationBrick",
                user: this.auth.email,
                newData: brick_data,
                presentationData: presentations,
                onPresentationData: on_presentations,
                superbPresentationData: superbPresentations,
                comercios_on_presentations: comercios_on_presentations
            })
            brick_data.update_at = new Date();

            let bar_codeArray = []
            brick_data.codeSAP  = []
            presentations.forEach(element => {
                // console.log("element => ", element)
                let { bar_code } = element;

                if(this.auth.countryCode == 'DO'){
                    element.inventory = (element.inventory_ZONE1 ? element.inventory_ZONE1 : 0) + (element.inventory_ZONE2 ? element.inventory_ZONE2 : 0)
                }
                
                if(this.auth.countryCode == 'SV' && element.hasOwnProperty('discountPorcentage') && (element.discountPorcentage != '' || element.discountPorcentage != 0 || element.discountPorcentage != '0')){
                    element.undiscounted_price = Number((Number(element.price) + (element.price * (Number(element.discountPorcentage)/100))).toFixed(2))
                }
                if(element.hasOwnProperty('newPorcentage')){
                    delete element.newPorcentage
                    delete element.undiscounted_price
                }

                // if(this.auth.countryCode != 'SV' && element.hasOwnProperty('newPorcentage') && (element.newPorcentage != '' || element.newPorcentage != 0 || element.newPorcentage != '0')){
                //         if(element.hasOwnProperty('newPorcentage') && element.hasOwnProperty('discountPorcentage') && element.newPorcentage != element.discountPorcentage){
                //             console.log("entro")
                //             element.undiscounted_price = element.price
                //             element.discountPorcentage = element.newPorcentage;
                //             element.price = Number((Number(element.price) - (element.price * (Number(element.discountPorcentage)/100))).toFixed(2))
                            
                //         }
                        
                // }
                
                if (bar_code != undefined && bar_code != '') {
                    bar_codeArray.push(bar_code)
                }
                
                if(element.hasOwnProperty('codeSap') && element.codeSap != ''){
                    brick_data.codeSAP.push(element.codeSap)
                }

                if (element.price_bin > 0) {
                    console.log("element.price_bin => ", element.price_bin)
                    batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key),
                        element
                    );
                }
                else {
                    // delete element.price_bin
                    
                    batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key),
                        element
                    );
                    batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key), { price_bin: firebase.firestore.FieldValue.delete() });
                }

                if (element.quantity_limit && (element.quantity_limit <= 0 || element.quantity_limit == '' || element.quantity_limit == undefined)) {
                    batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key), { quantity_limit: firebase.firestore.FieldValue.delete() });
                }

                

                if (element.hasOwnProperty('discountPorcentage') && (element.discountPorcentage === '' || element.discountPorcentage <= 0 || element.discountPorcentage === '0' || element.discountPorcentage === null)){
                    
                    if(element.hasOwnProperty('undiscounted_price') && element.hasOwnProperty('discountPorcentage')){
                        batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key), { discountPorcentage: firebase.firestore.FieldValue.delete(),  undiscounted_price: firebase.firestore.FieldValue.delete()});
                    }else{
                        batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key), { discountPorcentage: firebase.firestore.FieldValue.delete(), undiscounted_price: firebase.firestore.FieldValue.delete()});
                    }
                }

                if(element.hasOwnProperty('newPorcentage')){
                    batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/presentations/`).doc(element.$key), { undiscounted_price: firebase.firestore.FieldValue.delete(),  newPorcentage: firebase.firestore.FieldValue.delete()});
                }

            });

            if (bar_codeArray.length > 0) {
                brick_data['bar_code'] = bar_codeArray
            } else {
                brick_data['bar_code'] = null
            }

            // return
            on_presentations.forEach(element => {
                batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/on_presentations/`).doc(element.$key),
                    element
                );
            });

            // manejo de collections superb
            superbPresentations.forEach(element => {
                batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/superb_presentations/`).doc(element.$key),
                    element
                );
            });
            comercios_on_presentations.forEach(element => {
                batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/comercios_on_presentations/`).doc(element.$key),
                    element
                );
            });


            superb_on_presentations.forEach(element => {
                batch.update(this.db.firestore.collection(`bricks/${brick_data.$key}/superb_on_presentations/`).doc(element.$key),
                    element
                );
            });
            // fin de manejo de collections superb
            // console.log("brick_data = ", brick_data)

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`bricks/${brick_data.category}/${brick_data.$key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        let arrayImage = []
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            // 
                            // console.log("data => ", data)
                            brick_data.imagen_carrusel['downloadURL'][0] = data
                            if (brick_data.imagen_carrusel['downloadURL']) {
                                for (let i = 0; i < brick_data.imagen_carrusel['downloadURL'].length; i++) {
                                    if (brick_data.imagen_carrusel['downloadURL'][i] == '') {
                                        delete brick_data.imagen_carrusel['downloadURL'][i]
                                    } else {
                                        arrayImage.push(brick_data.imagen_carrusel['downloadURL'][i])
                                    }
                                }
                                brick_data.imagen_carrusel['downloadURL'] = arrayImage
                            }
                            // 
                            batch.update(this.db.firestore.collection(`bricks`).doc(brick_data.$key),
                                brick_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.update(this.db.firestore.collection(`bricks`).doc(brick_data.$key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    // eliminar campos de documento en base

    deleteFieldsDocument(collection, doc_key, list_fields) {
        return new Promise((resolve, reject) => {
            // console.log("collection,", collection, "doc_key,", doc_key, "list_fields", list_fields)
            for (var i = 0; i < list_fields.length; i++) {
                // console.log(list_fields[i])
                this.updateDocument(collection, doc_key,
                    { [list_fields[i]]: firebase.firestore.FieldValue.delete() }
                )
            }
            resolve(null);
        });
    }

    updateExplore(brick_data, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`explore/${brick_data.category}/${brick_data.$key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.update(this.db.firestore.collection(`explore`).doc(brick_data.$key),
                                brick_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.update(this.db.firestore.collection(`explore`).doc(brick_data.$key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    private generateUUID(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    updatePromotion(promotion_data, image) {
        return new Promise((resolve, reject) => {

            let batch = this.db.firestore.batch();

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`promotions/${promotion_data.$key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            promotion_data.image = photoData;
                            batch.update(this.db.firestore.collection(`promotions`).doc(promotion_data.$key),
                                promotion_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.update(this.db.firestore.collection(`promotions`).doc(promotion_data.$key),
                    promotion_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    verifyAccount(data_request) {
        //console.log(typeof (data_request))
        //console.log(data_request)
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            let deliveries = 0;
            let partnerFreeDeliveries = 0
            // let data = data_request.partner

            // partnerFreeDeliveries = data_request.partner.deliveries
            // partner_key
            //console.log(data_request.partner_key)
            this.getDocument('partners', data_request.partner_key).then(data => {
                //console.log(data)
                partnerFreeDeliveries = data['deliveries']
                //console.log(partnerFreeDeliveries)
                //console.log("-------")
                //console.log(data_request.account.free_deliveries);
                //console.log(typeof (data_request.account.free_deliveries));

                // if (data_request.account.free_deliveries) {
                if (data_request.account.hasOwnProperty('free_deliveries')) {
                    //console.log("la propiedad existe");

                    //console.log(data_request.account.free_deliveries)
                    deliveries = Number(partnerFreeDeliveries) + Number(data_request.account.free_deliveries);
                    batch.update(this.db.firestore.collection(`accounts`).doc(data_request.account.$key),
                        {
                            verification: true,
                            partner: data_request.partner_key,
                            free_deliveries: deliveries
                        }
                    );
                    batch.update(this.db.firestore.collection(`verifications`).doc(data_request.$key),
                        data_request
                    );

                    batch.commit().then(data => {

                        this.getAllDocuments(`accounts/${data_request.user}/push_keys`).then(keys => {
                            if (keys) {
                                let push_keys = [];
                                keys.forEach(element => {
                                    push_keys.push(element.push_key);
                                });

                                this.sendPush(`Cuenta verificada ✅`, 'Tu cuenta ha sido verificada correctamente', push_keys);
                            }
                        })

                        resolve(null);
                    })
                } else {
                    batch.update(this.db.firestore.collection(`accounts`).doc(data_request.account.$key),
                        {
                            verification: true,
                            partner: data_request.partner_key,
                            free_deliveries: 3
                        }
                    );
                    batch.update(this.db.firestore.collection(`verifications`).doc(data_request.$key),
                        data_request
                    );

                    batch.commit().then(data => {

                        this.getAllDocuments(`accounts/${data_request.user}/push_keys`).then(keys => {
                            if (keys) {
                                let push_keys = [];
                                keys.forEach(element => {
                                    push_keys.push(element.push_key);
                                });

                                this.sendPush(`Cuenta verificada ✅`, 'Tu cuenta ha sido verificada correctamente', push_keys);
                            }
                        })
                        resolve(null);
                    })
                }
            })
        })
    }

    cancelVerifyAccount(data_request) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            let deliveries = 0;

            batch.update(this.db.firestore.collection(`accounts`).doc(data_request.user),
                {
                    verification: false,
                    partner: '',
                    free_deliveries: deliveries
                }
            );

            batch.update(this.db.firestore.collection(`verifications`).doc(data_request.$key),
                data_request
            );

            batch.commit().then(data => {
                this.deleteDocument(`verifications`, data_request.$key).then(data => {
                    //console.log(data)
                });
                resolve(null);
            })
        })
    }

    async sendPush(title, message, push_keys) {
        // let respAndroid = await this.sendPushAndroid(title, message, push_keys)
        // console.log("respAndroid = ", respAndroid)
        let respIos = await this.sendPushIos(title, message, push_keys)
        // console.log("respIos = ", respIos)
    }

    sendPushAndroid(title, message, push_keys) {
        //console.log(title);
        //console.log(message);

        return new Promise((resolve, reject) => {
            const post_data = {
                'app_id': 'e3c4658e-f96c-4430-8771-be07abbcc65c',
                'headings': {
                    'en': title,
                    'es': title,
                },
                'contents': {
                    'en': message,
                    'es': message,
                },
                "include_player_ids": push_keys,
            }

            let headers = new HttpHeaders({
                'Authorization': `Basic ZmZiZmZiNTAtM2FiYi00MjNmLWEzY2YtNTgwZjI3YTUwOTJh`
            });

            this.http.post('https://onesignal.com/api/v1/notifications', post_data, { headers: headers })
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

    sendPushIos(title, message, push_keys) {
        //console.log(title);
        //console.log(message);

        return new Promise((resolve, reject) => {
            const post_data = {
                'app_id': 'fe552472-4831-4d56-b608-7672e23b3030',
                'headings': {
                    'en': title,
                    'es': title,
                },
                'contents': {
                    'en': message,
                    'es': message,
                },
                "include_player_ids": push_keys,
            }

            let headers = new HttpHeaders({
                'Authorization': `Basic ZDgwOTFiNjYtOTY5Ni00OGZhLThhMzktNzcyMzMzYjNiNmIz`
            });

            this.http.post('https://onesignal.com/api/v1/notifications', post_data, { headers: headers })
                .subscribe(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                });
        });
    }

    // se modifica cuando la orden se le aplicaron los puntos desde monitor
    cancelOrder(order) {
        return new Promise(async (resolve, reject) => {
            let batch = this.db.firestore.batch();
            if (order.status == 'delivered') {
                await this.getDocument(`accounts`, order.account.$key).then(account => {
                    // contruyendo Key del country relacionado a la orden

                    // inicio de validaciones de los puntos points_remove_applied / points_add_applied
                    if (!(order.hasOwnProperty("points_revert_applied"))) {
                        let index_points
                        let index_points_partner;
                        let newPoints = `points_${this.auth.storeCode}_${this.auth.countryCode}`


                        if (order.countryCode == 'SV') {
                            index_points = newPoints // 'points'
                            index_points_partner = 'points_partner'
                        }
                        else {
                            index_points = newPoints // `points_${order.countryCode}`
                            index_points_partner = `points_partner_${order.countryCode}`
                        }

                        // para remover los puntos ganados en la compra
                        // obteniendo los puntos acount segun el order.countryCode
                        let country_points = 0
                        if (account.hasOwnProperty(index_points)) {
                            country_points = Number(account[index_points]) > 0 ? Number(account[index_points]) : 0;
                        }
                        let country_points_partner = 0
                        if (account.hasOwnProperty(index_points_partner)) {
                            country_points_partner = Number(account[index_points_partner]) > 0 ? Number(account[index_points_partner]) : 0
                        }
                        //console.log("country_points", country_points, "country_points_partner", country_points_partner)

                        // para revertir la compra de productos con Puntos en Club Barlleno
                        let points = country_points - (Number(order.points_earned) > 0 ? Number(order.points_earned) : 0) + (Number(order.total_points) > 0 ? Number(order.total_points) : 0)
                        if (points < 0) {
                            points = 0
                        }
                        let points_partner = country_points_partner - (Number(order.points_earned_partner) > 0 ? Number(order.points_earned_partner) : 0) + (Number(order.total_points_partner) > 0 ? Number(order.total_points_partner) : 0)
                        //console.log("set points_partner => ", points_partner)
                        if (points_partner < 0) {
                            points_partner = 0
                        }

                        batch.update(this.db.firestore.collection(`accounts`).doc(order.account.$key),
                            { [index_points]: points }
                        );

                        batch.update(this.db.firestore.collection(`accounts`).doc(order.account.$key),
                            { [index_points_partner]: points_partner }
                        );
                        // agregando nueva bandera para saber si los puntos fueron removidos de la cuenta del usuario
                        batch.update(this.db.firestore.collection(`orders`).doc(order.$key),
                            {
                                status: 'canceled',
                                points_revert_applied: true
                            }
                        );
                    } else {
                        // //console.log("no existen las banderas de control - points_remove_applied / points_add_applied")
                        batch.update(this.db.firestore.collection(`orders`).doc(order.$key),
                            {
                                status: 'canceled'
                            }
                        );
                    }

                    // fin de validaciones de los puntos points_remove_applied / points_add_applied

                    // batch.update(this.db.firestore.collection(`orders`).doc(order.$key),
                    //     {
                    //         status: 'canceled'
                    //     }
                    // );

                    batch.commit().then(async data => {
                        let respReverInventory = await this.revertInventory(order)
                        //console.log("respRevertInventory = ", respReverInventory)

                        resolve(null)
                    })
                })
            } else if (order.status == 'canceled') {
            } else {
                await this.getDocument(`accounts`, order.account.$key).then(account => {
                    // let points = account['points'] - Number(order.points_earned);
                    // contruyendo Key del country relacionado a la orden

                    // inicio de validaciones de los puntos points_remove_applied / points_add_applied
                    // if (!(order.hasOwnProperty("points_revert_applied")) && (order.hasOwnProperty("points_add_applied") || order.hasOwnProperty("points_remove_applied")) ) {
                    if (!(order.hasOwnProperty("points_revert_applied"))) {
                        let index_points
                        let index_points_partner;

                        // order.points_revert_applied = true

                        if (order.countryCode == 'SV') {
                            index_points = 'points'
                            index_points_partner = 'points_partner'
                        }
                        else {
                            index_points = `points_${order.countryCode}`
                            index_points_partner = `points_partner_${order.countryCode}`
                        }

                        // para remover los puntos ganados en la compra
                        // obteniendo los puntos acount segun el order.countryCode
                        let country_points = 0
                        if (account.hasOwnProperty(index_points)) {
                            country_points = Number(account[index_points]) > 0 ? Number(account[index_points]) : 0;
                        }
                        let country_points_partner = 0
                        if (account.hasOwnProperty(index_points_partner)) {
                            country_points_partner = Number(account[index_points_partner]) > 0 ? Number(account[index_points_partner]) : 0
                        }
                        //console.log("country_points", country_points, "country_points_partner", country_points_partner)

                        // para revertir la compra de productos con Puntos en Club Barlleno
                        let points = country_points + (Number(order.total_points) > 0 ? Number(order.total_points) : 0)
                        //console.log("set points => ", points)
                        if (points < 0) {
                            points = 0
                        }
                        let points_partner = country_points_partner + (Number(order.total_points_partner) > 0 ? Number(order.total_points_partner) : 0)
                        //console.log("set points_partner => ", points_partner)
                        if (points_partner < 0) {
                            points_partner = 0
                        }

                        batch.update(this.db.firestore.collection(`accounts`).doc(order.account.$key),
                            { [index_points]: points }
                        );

                        batch.update(this.db.firestore.collection(`accounts`).doc(order.account.$key),
                            { [index_points_partner]: points_partner }
                        );
                        // agregando nueva bandera para saber si los puntos fueron removidos de la cuenta del usuario
                        batch.update(this.db.firestore.collection(`orders`).doc(order.$key),
                            {
                                status: 'canceled',
                                points_revert_applied: true
                            }
                        );

                    } else {
                        // //console.log("no existen las banderas de control - points_remove_applied / points_add_applied")
                        batch.update(this.db.firestore.collection(`orders`).doc(order.$key),
                            {
                                status: 'canceled'
                            }
                        );
                    }

                    // fin de validaciones de los puntos points_remove_applied / points_add_applied

                    // batch.update(this.db.firestore.collection(`orders`).doc(order.$key),
                    //     {
                    //         status: 'canceled'
                    //     }
                    // );

                    batch.commit().then(async data => {
                        let respReverInventory = await this.revertInventory(order)
                        //console.log("respRevertInventory = ", respReverInventory)

                        //console.log("ingreso a metodo revertir visible / no visible segun existencias de inventario")
                        // order.bricks.forEach(async element => {
                        //     //console.log("item del brick")
                        //     //console.log(element);
                        //     let quantityRevert = element.quantity
                        //     let currentInvetory = 0
                        //     // this.getAllDocuments(`bricks/${element.brick_key}/presentations/${element.$key}/`).then(data => {
                        //     await this.getDocument(`bricks/${element.brick_key}/presentations/`, element.$key).then(data => {
                        //         //console.log("data bricks/${element.brick_key}/presentations/`, element.$key");
                        //         //console.log(data)
                        //         if (data) {
                        //             if (data['inventory'] >= 0) {
                        //                 currentInvetory = data['inventory']
                        //             } else {
                        //                 currentInvetory = 0
                        //             }

                        //             // //console.log("valores de los inventarios");
                        //             // //console.log("quantityRevert");
                        //             // //console.log(quantityRevert);
                        //             // //console.log("currentInvetory");
                        //             // //console.log(currentInvetory);

                        //             let batch = this.db.firestore.batch();

                        //             batch.update(this.db.firestore.collection(`bricks/${element.brick_key}/presentations/`).doc(element.$key),
                        //                 { inventory: quantityRevert + currentInvetory, visible: (quantityRevert + currentInvetory) > 0 ? true : false }
                        //             );

                        //             if (quantityRevert + currentInvetory > 0) {
                        //                 batch.update(this.db.firestore.collection(`bricks/`).doc(element.brick_key),
                        //                     { visible: true }
                        //                 );
                        //             }
                        //             batch.commit().then(data => {
                        //             })
                        //         }
                        //     })
                        // });
                    })
                })
            }
            resolve(null)
        })
    }

    async revertInventory(order) {
        return new Promise(async (resolve, reject) => {
            // console.log("revertInventory = ", order)
            let resp: any = false
            let respAjuste: any = false
            // account.legal_entity
            if (order.account.legal_entity == true) {
                resp = await this.revertInventoryAux(order, 'on_presentations')
                // console.log("resp = ", resp)
                respAjuste = await this.revertInventoryAjustON(order, 'presentations')
                // console.log(respAjuste)
            } else {
                resp = await this.revertInventoryAux(order, 'presentations')
                // console.log("resp = ", resp)
                // barlleno normal no afecta inventario de barlleno ON
            }

            resolve(resp)

        })
    }

    async revertInventoryAjustON(order, presentations) {
        return new Promise(async (resolve, reject) => {
            // console.log("revertInventoryAjust")
            for (let index = 0; index < order['bricks'].length; index++) {
                const element = order['bricks'][index];
                let flagExistencias = true;
                // console.log("revertInventoryAjustON = ", `bricks/${element.brick.$key}/${presentations}/`)
                await this.getDocument(`bricks/${element.brick.$key}/on_presentations/`, element.$key).then(async presentation => {
                    // console.log(presentation["presentation"]);
                    await this.getAllDocuments(`bricks/${element.brick.$key}/presentations/`).then(async item => {
                        if (item) {
                            for (let pos = 0; pos < item.length; pos++) {
                                const elementItem = item[pos];
                                let flagVisible = true
                                // console.log(elementItem)
                                if (elementItem.presentation == presentation["presentation"]) {
                                    let inventory = Number(elementItem.inventory) - Number(element.quantity);
                                    if (inventory <= 0) {
                                        inventory = 0;
                                        flagVisible = false
                                    }
                                    // console.log(`bricks/${element.brick.$key}/presentations/`, "elementItem.$key = ", elementItem.$key)
                                    await this.updateDocument(`bricks/${element.brick.$key}/presentations/`, elementItem.$key, {
                                        inventory: Number(inventory) > 0 ? Number(inventory) : 0,
                                        visible: flagVisible,
                                    }).then(async data => {
                                        // console.log("inventory", inventory);
                                        // console.log("elementItem.inventory", elementItem.inventory);
                                    })
                                }
                            }
                        }
                    })
                })
            }

            resolve(true)
        })
    }

    async revertInventoryAux(order, presentations) {
        return new Promise(async (resolve, reject) => {
            // console.log("ingreso a metodo revertir visible / no visible segun existencias de inventario")
            // order.bricks.forEach(async element => {
            // console.log("item del brick")
            // console.log(element);
            for (let index = 0; index < order['bricks'].length; index++) {
                const element = order['bricks'][index];
                //console.log("order['bricks'] element === ", element)
                if (true) {
                    let quantityRevert = element.quantity
                    let currentInvetory = 0
                    // this.getAllDocuments(`bricks/${element.brick_key}/presentations/${element.$key}/`).then(data => {
                    //console.log("revertInventoryAux ", `bricks/${element.brick_key}/${presentations}/`)
                    await this.getDocument(`bricks/${element.brick_key}/${presentations}/`, element.$key).then(data => {
                        // console.log("data bricks/${element.brick_key}/presentations/`, element.$key");
                        // console.log(data)
                        if (data) {
                            if (data['inventory'] >= 0) {
                                currentInvetory = data['inventory']
                            } else {
                                currentInvetory = 0
                            }

                            // console.log("valores de los inventarios");
                            // console.log("quantityRevert");
                            // console.log(quantityRevert);
                            // console.log("currentInvetory");
                            // console.log(currentInvetory);

                            let batch = this.db.firestore.batch();
                            //console.log("revertInventoryAux ", `bricks/${element.brick_key}/${presentations}/`)
                            batch.update(this.db.firestore.collection(`bricks/${element.brick_key}/${presentations}/`).doc(element.$key),
                                { inventory: quantityRevert + currentInvetory, visible: (quantityRevert + currentInvetory) > 0 ? true : false }
                            );

                            if (quantityRevert + currentInvetory > 0) {
                                batch.update(this.db.firestore.collection(`bricks/`).doc(element.brick_key),
                                    { visible: true }
                                );
                            }
                            batch.commit().then(data => {
                                resolve(true)
                            })
                        }
                    })
                }
            }
            // });
        })
    }

    cancelOrderCredit(order) {
        return new Promise(async (resolve, reject) => {
            let batch = this.db.firestore.batch();
            if (order.status == 'delivered') {
                if (!(order.hasOwnProperty("points_revert_applied"))) {
                    // agregando nueva bandera para saber si los puntos fueron removidos de la cuenta del usuario
                    batch.update(this.db.firestore.collection(`requests-orders`).doc(order.$key),
                        {
                            status: 'canceled',
                            points_revert_applied: true
                        }
                    );
                } else {
                    batch.update(this.db.firestore.collection(`requests-orders`).doc(order.$key),
                        {
                            status: 'canceled'
                        }
                    );
                }
                // confirmando los cambios
                batch.commit().then(data => {
                    //console.log("se cambia el estado de la orden en request-orders a canceled / no se afecta el invetnario de los bricks incluidos")
                })

            } else if (order.status == 'canceled') {
                // no se hace nada.
                //console.log("el estado de la orden ya era canceled")
            } else {
                if (!(order.hasOwnProperty("points_revert_applied"))) {
                    // agregando nueva bandera para saber si los puntos fueron removidos de la cuenta del usuario
                    batch.update(this.db.firestore.collection(`requests-orders`).doc(order.$key),
                        {
                            status: 'canceled',
                            points_revert_applied: true
                        }
                    );
                } else {
                    // console.log("no existen las banderas de control - points_remove_applied / points_add_applied")
                    batch.update(this.db.firestore.collection(`requests-orders`).doc(order.$key),
                        {
                            status: 'canceled'
                        }
                    );
                }
                batch.commit().then(data => {
                    //console.log("se actualiza el estado a canceled - cuando el estado de la orden es distinto a delivery")
                })
            }

            resolve(null)
        })
    }


    // crear un nuevo empleado en delivery
    updateCollaborator(collaborator_data, key_id, image,) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`delivery/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            collaborator_data.image = photoData;
                            batch.update(this.db.firestore.collection(`delivery`).doc(key_id.id),
                                { photo: photoData }
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })
            } else {
                batch.update(this.db.firestore.collection(`delivery`).doc(key_id.id), {}
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    // creacion de brick en mixology

    addIngredient(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            let brick_key = this.db.createId();
            dataObj.$key = brick_key
            batch.set(this.db.firestore.collection(collectionName).doc(brick_key), dataObj);
            batch.commit().then(data => {
                resolve(null);
            })
        });
    }

    addBrickMixology(brick_data, ingredients, recommended, image) {
        return new Promise((resolve, reject) => {
            //console.log("brick_data", brick_data, "ingredients", ingredients, "recommended", recommended, "image", image)
            let batch = this.db.firestore.batch();

            brick_data.created_at = new Date();

            let brick_key = this.db.createId();
            brick_data.created_at = new Date();
            brick_data.$key = brick_key
            //console.log("brick_key", brick_key)

            ingredients.forEach(element => {
                let ingredient_key = this.db.createId();
                batch.set(this.db.firestore.collection(`explore/${brick_key}/ingredients/`).doc(ingredient_key),
                    element
                );
            });

            // cuando el brick ya existe
            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`explore/${brick_key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                                { ...brick_data }
                            );
                            //console.log(brick_data);
                            batch.commit().then(data => {
                                // ejecutando cuando el brick ya existe en base de datos
                                recommended.forEach(element => {
                                    //     let batch = this.db.firestore.batch();

                                    let brickReference = {
                                        brick: brick_key,
                                        visible: element.visible,
                                        reference: element.reference
                                    }
                                    //     // batch.set(this.db.firestore.collection(`recommended/`).doc(recommended_key),
                                    //     //     brickReference
                                    //     // );
                                    this.addDocument('recommended', brickReference).then(data => {
                                    });
                                });
                                resolve(null);
                            })
                        });
                    })
            } else {
                batch.set(this.db.firestore.collection(`explore`).doc(brick_key),
                    { ...brick_data }
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    updateBrickMixology(brick_data, ingredients, image) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            ingredients.forEach(element => {
                batch.update(this.db.firestore.collection(`explore/${brick_data.$key}/ingredients/`).doc(element.$key),
                    element
                );
            });

            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`explore/${brick_data.category}/${brick_data.$key}/${filename}.png`);

                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            brick_data.image = photoData;
                            batch.update(this.db.firestore.collection(`explore`).doc(brick_data.$key),
                                brick_data
                            );
                            batch.commit().then(data => {
                                resolve(null);
                            })
                        });
                    })

            } else {
                batch.update(this.db.firestore.collection(`explore`).doc(brick_data.$key),
                    brick_data
                );
                batch.commit().then(data => {
                    resolve(null);
                })
            }
        });
    }

    addPrincipaIngredients(obj) {
        return new Promise((resolve, reject) => {
            //console.log("addPrincipaIngredients", obj)
            let batch = this.db.firestore.batch();
            let brick_key = this.db.createId();
            obj.$key = brick_key

            batch.set(this.db.firestore.collection(`catalogue`).doc(brick_key),
                obj
            );
            batch.commit().then(data => {
                resolve(null);
            })
        });
    }

    addDocumentKey(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let brick_key = this.db.createId();
            dataObj.$key = brick_key
            let batch = this.db.firestore.batch();
            batch.set(this.db.firestore.collection(collectionName).doc(brick_key),
                dataObj
            );
            batch.commit().then(data => {
                resolve(brick_key);
            })
        });
    }

    addParnerTriggers(collectionName: string, dataObj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            // countryCode
            // dataObj.countryCode
            let countryCode = ''
            if (this.auth.countryCode == 'SV') {
                countryCode = 'general'
            } else {
                countryCode = `${this.auth.countryCode}`
            }
            // 
            let brick_key = this.db.createId();
            dataObj.$key = brick_key
            let batch = this.db.firestore.batch();
            batch.set(this.db.firestore.collection(collectionName).doc(brick_key),
                dataObj
            );
            batch.commit().then(data => {
                let key = this.db.createId();
                let keyAux = this.db.createId();
                let keyTemp = this.db.createId();
                let batch = this.db.firestore.batch();
                // creando category name : crew
                let obj = {
                    $key: key,
                    countryCode: dataObj.countryCode,
                    created_at: new Date(),
                    visible: true,
                    type: 'category',
                    position: 0,
                    category: dataObj.$key,
                    name: dataObj.name + ' Crew',
                    partnerInfo: true,
                    partner: true,
                    supplier_partner: brick_key
                }
                batch.set(this.db.firestore.collection(collectionName).doc(key),
                    obj
                );

                // creando category name : Kits
                let objAux = {
                    $key: keyAux,
                    countryCode: dataObj.countryCode,
                    created_at: new Date(),
                    visible: true,
                    type: 'category',
                    position: 1,
                    category: dataObj.$key,
                    partner: true,
                    name: 'Kits ' + dataObj.name,
                    supplier_partner: brick_key
                }
                batch.set(this.db.firestore.collection(collectionName).doc(keyAux),
                    objAux
                );

                // creando category name : Club
                let objTemp = {
                    $key: keyTemp,
                    countryCode: dataObj.countryCode,
                    created_at: new Date(),
                    visible: true,
                    type: 'category',
                    position: 2,
                    category: dataObj.$key,
                    partner: true,
                    club: true,
                    name: 'Club ' + dataObj.name,
                    supplier_partner: brick_key
                }

                batch.set(this.db.firestore.collection(collectionName).doc(keyTemp),
                    objTemp
                );
                this.getDocument(`configurations`, countryCode).then(data => {
                    if (data) {
                        let batchAux = this.db.firestore.batch();

                        let partner = data['partners']
                        //console.log(typeof partner, "partner = ", partner)
                        let objPartner = {
                            $key_partner: brick_key,
                            name: dataObj.name,
                            botonera: {
                                'option1': {
                                    $key_category: key,
                                    downloadUrl: '',
                                    name: 'informativo',
                                    routerLink: '',
                                    position: 0
                                }
                                ,
                                'option2': {
                                    $key_category: keyAux,
                                    downloadUrl: '',
                                    name: 'cash',
                                    routerLink: 'category',
                                    position: 1
                                }
                                ,
                                'option3': {
                                    $key_category: keyTemp,
                                    downloadUrl: '',
                                    name: 'puntos',
                                    routerLink: 'club/category',
                                    position: 2
                                }
                            },
                            visible: true,
                        }
                        var nameAux = dataObj.name
                        //console.log("objPartner = ", objPartner)
                        data['partners'][nameAux] = objPartner // [{'partnes' : objPartner}]

                        batchAux.update(this.db.firestore.collection(`configurations`).doc(countryCode),
                            data
                        );
                        batchAux.commit()
                    }
                })

                batch.commit().then(data => {
                    resolve(null);
                });
            })
        });
    }

    GeneralInfo: any;
    deleteParnerTriggers(collectionName: string, countryCode: any, category_key: any): Promise<any> {
        return new Promise((resolve, reject) => {
            //console.log("category_key", category_key)
            this.getDocument('configurations', countryCode).then(async data => {
                this.GeneralInfo = data;
                //console.log("this.GeneralInfo", this.GeneralInfo, typeof this.GeneralInfo, "category_key", category_key)
                //console.log("this.GeneralInfo['partners']", this.GeneralInfo['partners'], typeof this.GeneralInfo['partners'])
                await Object.entries(this.GeneralInfo['partners']).forEach(async ([key, value]) => {
                    //console.log(key, value, value.hasOwnProperty("$key_partner"))
                    if (value.hasOwnProperty("$key_partner") && value['$key_partner'] == category_key) {
                        // estoy a nivel del nodo del partner
                        //console.log("encontrado")
                        delete this.GeneralInfo['partners'][key]
                    }
                });
                //console.log("new this.GeneralInfo ", this.GeneralInfo)
                await this.updateDocument('configurations', countryCode, this.GeneralInfo).then(data => {
                    resolve(null);
                })
            })
        });
    }

    uploadFile(image) {
        return new Promise((resolve, reject) => {
            if (image) {
                let storageRef = firebase.storage().ref();
                let imageRef = storageRef.child(`chats/${new Date().getTime()}-${image.name}`);

                imageRef.put(image)
                    .then(data => {
                        imageRef.getDownloadURL().then(data => {
                            resolve(data);
                        });

                    })
            } else {
                resolve('');
            }

        });
    }

    addUserStore(path, userData) {
        return new Promise((resolve, reject) => {
            let batch = this.db.firestore.batch();
            let key = this.db.createId();
            userData.$key = key

            batch.set(this.db.firestore.collection(path).doc(userData.$key),
                userData
            );

            batch.commit().then(data => {
                resolve(null);
            })
        });

    }

    // addAuthorizationUser - regitrar acceso a usuarios en monitor segun la tienda actual
    addAuthorizationUser(countryPath, countryCode, storeKey, users_collections, user_email, user_key, user_role) {
        return new Promise(async (resolve, reject) => {
            // init batch de proceso de registro de credenciales y acceso del usuario

            let data = {}
            let batch = this.db.firestore.batch();
            // agreando los datos en configurations del pais
            batch.set(this.db.firestore.collection(`configurations`).doc(countryPath),
                users_collections
            );

            data['$key'] = user_key
            data['countryCode'] = countryCode
            data['email'] = user_email

            // console.log("data = ", data)
            batch.set(this.db.firestore.collection(`users-stores`).doc(user_key),
                data
            );

            let data_user = {}
            let user_store_key = this.db.createId();

            data_user['$key'] = user_store_key
            data_user['active'] = true
            data_user['countryCode'] = countryCode
            data_user['created_at'] = new Date();
            data_user['email'] = user_email
            data_user['role'] = user_role
            data_user['store'] = storeKey

            batch.set(this.db.firestore.collection(`users-stores`).doc(user_store_key),
                data_user
            );

            batch.commit().then(data => {
                resolve('exito en agregar el usuario a administracion de cuentas');
            })

        });
    }

    // creando elemento de historial de modificaciones
    updateAccountLog(brick_data, origine) {
        return new Promise(async (resolve, reject) => {
            let objLog = []
            if (brick_data['account_email_update_log'] != undefined) {
                objLog = brick_data['account_email_update_log']
            }
            objLog.push({ email: this.auth.email, dateUpdate: new Date(), action: origine })
            resolve(objLog);
        });
    }

    // guardando notificacion push en base para recuperarla desde app movil
    addNotification(obj, category_image_src) {
        return new Promise(async (resolve, reject) => {
            let batch = this.db.firestore.batch();
            let brick_key = this.db.createId();
            obj.$key = brick_key
            obj.account_email_create = this.auth.email

            if (category_image_src) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`notifications/${filename}.png`);
                imageRef.putString(category_image_src, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        let arrayImage = []
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(dataResponse => {
                            // photoData['downloadURL'] = dataResponse;
                            obj.image = { 'downloadURL': '' }
                            // console.log("dataResponse = ", dataResponse)
                            obj.image['downloadURL'] = dataResponse;
                            batch.set(this.db.firestore.collection(`notifications`).doc(brick_key),
                                { ...obj }
                            );
                            batch.commit().then(data => {
                                resolve('success, notificacion almacenada en base');
                            })
                        });
                    })
            } else {
                resolve('fails, no hay imagen guardada');
            }
        });
    }

    pushImageStorage(image) {
        return new Promise((resolve, reject) => {
            if (image) {
                let storageRef = firebase.storage().ref();
                const filename = this.generateUUID();
                let imageRef = storageRef.child(`notifications/${filename}.png`);
                imageRef.putString(image, firebase.storage.StringFormat.DATA_URL)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            // response de la carga de la imagen
                            // console.log("photoData = ", photoData)
                            resolve(photoData);
                        });
                    })
            }
        });
    }
    async pushImageStorage$(image, path='notifications') {
        return new Promise((resolve, reject) => {

            if (image) {
                let storageRef = firebase.storage().ref();
                let filename = this.generateUUID();
                let metadata = {
                    contentType: 'image/jpeg',
                };
                let imageRef = storageRef.child(`${path}/${filename}.png`);
                imageRef.put(image)
                    .then(data => {
                        let photoData = {};
                        photoData['fullPath'] = data.metadata.fullPath;
                        photoData['size'] = data.metadata.size;
                        photoData['contentType'] = data.metadata.contentType;
                        photoData['md5Hash'] = data.metadata.md5Hash;
                        photoData['bucket'] = data.metadata.bucket;
                        photoData['updated'] = data.metadata.updated;
                        imageRef.getDownloadURL().then(data => {
                            photoData['downloadURL'] = data;
                            // response de la carga de la imagen
                            // console.log("photoData = ", photoData)
                            resolve(photoData);
                        });
                    })
            }
        });
    }
}



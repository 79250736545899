import { Pipe, PipeTransform } from '@angular/core';
import { BrickElement } from '../interfaces/orderPoints';

@Pipe({
  name: 'productos'
})
export class ProductosPipe implements PipeTransform {

  transform(bricks: BrickElement[], ...args: any[]): string {  
    let productos = '';

    bricks.forEach(brick => {
      if(brick.type == 'club'){
        productos = productos.concat(`${brick.brick.name}, `)
      }      
    });
    return productos;
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Order } from '../../interfaces/order.interface';
import { DataOrderClient } from '../../interfaces/orderPoints';
import { ReportsService } from './reports.service';

@Injectable({
  providedIn: 'root'
})
export class EncuentasServiceService {

  constructor(
    private firebase: AngularFirestore,
    private reportsService: ReportsService
  ) { }

  // Variable para obtener el país
  private country = localStorage.getItem('countryCode');

  async getReporteEncuestas(startDate: Date, endDate: Date): Promise<DataOrderClient[]> {
    const ordersData: any = [];

    const initDate = new Date(startDate);
    const endTime = new Date(endDate);

    await this.firebase.collection('orders', ref => ref
      .where('countryCode', '==', this.country)
      .where('score',        "==" ,true)
      .where('added_date',  '>=', initDate)
      .where('added_date',  '<=', endTime)
    ).get().toPromise().then(
      orders => {
        orders.forEach(order => ordersData.push(order.data()));
      }
    );

    const data: DataOrderClient[] = await this.reportsService.getDataClient(ordersData);
    data.forEach(order => order.dataOrder.added_date = order.dataOrder.added_date.toDate())

    return data;
  }

}

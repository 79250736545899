import { AuthService } from 'src/app/services/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent implements OnInit {
  @Input() detailOrders: any;
  @Input() type: any;
  @Input() goals: any;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {

  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {

  constructor(protected router: Router, private auth:AuthService) { }

  ngOnInit() {}

  toHome(){
    if(this.auth.getCountryFromLocalStorage() != null ){
      if(["0","1","3","4","5"].includes(this.auth.role)){
        this.router.navigate(['/index/monitor-v2']);
      }else{
        this.router.navigate(['/index/section/dashboard']);
      }
    }else{
      this.router.navigate(['/login']);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UploadService } from 'src/app/services/uploads/upload.service';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
	selector: 'app-settings-zone',
	templateUrl: './settings-zone.component.html',
	styleUrls: ['./settings-zone.component.scss'],
})
export class SettingsZoneComponent implements OnInit {
	brickForm: FormGroup;
	zone_name = 'ZoneName'
	category_key: any = ''
	category: any = ''
	zone_event: any;
	value_max: any;
	min_value: any;

	constructor(
		public api: ApiService,
		private uploadService: UploadService,
		public formBuilder: FormBuilder,
		public loadingCtrl: LoadingController,
		public navParams: NavParams,
		public modalController: ModalController,
		public auth: AuthService,
		private SwalService: SwalService
	) {
		this.category = navParams.get('category');
		//  console.log("settings category ==> ", this.category)
		if (this.category.hasOwnProperty('name') && this.category.name != undefined && this.category.name != '') {
			this.zone_name = this.category.name
		}
		this.category_key = navParams.get('category_key');
		//  console.log("settings category_key ==> ", this.category_key)

		this.api.getDocument('events', this.category.event_key).then(data => {
			if (data) {
				this.zone_event = data['zone_event']
				//  console.log("this.zone_event = ", this.zone_event, "typeof this.zone_event = ", typeof this.zone_event)
				this.processZoneEvent(this.zone_event)
			}
		}, err => {
			//  console.log("la key del evento no se encontro en coleccion events")
		});


		this.brickForm = this.formBuilder.group({
			name: [this.category.name ? this.category.name : '', [Validators.required]],
			min_value: [this.min_value ? this.min_value : 0],
			value_max: [this.value_max ? this.value_max : 0],
		});
		this.getData()
	}

	ngOnInit() { }

	getData() {
		//  console.log("this.category = ", this.category)

		//  console.log("this.category_key = ", this.category_key)
	}

	async processZoneEvent(zone_event) {
		let resp = await this.getProcessInit(zone_event)
		//  console.log("resp = ", resp)
	}
	// Nodo principal - array
	getProcessInit(zone_event) {
		return new Promise(async (resolve, reject) => {
			//  console.log("typeof zone_event = ", typeof zone_event)
			let flagPrincipal = false
			if (typeof zone_event === 'object' && !zone_event.hasOwnProperty('sub_zone')) {
				// cuando es un array
				for (let index = 0; index < zone_event.length; index++) {
					let elementNode = zone_event[index];
					//  console.log("array element = ", elementNode)
					if (elementNode.$key == this.category_key) {
						flagPrincipal = true
						//  console.log("encontre el nodo $key buscado", elementNode.$key, this.category_key)
						elementNode.min_value ? this.min_value = elementNode.min_value : this.min_value = 0
						elementNode.value_max ? this.value_max = elementNode.value_max : this.value_max = 0
						this.brickForm.get('min_value').setValue(this.min_value)
						this.brickForm.get('value_max').setValue(this.value_max)
						break
					} else {
						flagPrincipal = false
						continue
					}
				}
				if (flagPrincipal == false) {
					//  console.log("flagPrincipal = ",flagPrincipal, "zone_event =", zone_event)
					let respNode = await this.getProcessNode(zone_event['sub_zone'])
					//  console.log("respNode = ", respNode)
				}
			}

			if (typeof zone_event === 'object' && zone_event.hasOwnProperty('sub_zone')) {
				//  console.log(" hasOwnProperty(sub_zone) ",  zone_event)
				let respNode = await this.getProcessNode(zone_event['sub_zone'])
				//  console.log("sub_zone respNode = ", respNode)
			}
			resolve(false)
		})
	}

	// Nodos internos sub_zone - map
	getProcessNode(zone_event) {
		return new Promise(async (resolve, reject) => {
			//  console.log("getProcessNode(zone_event) zone_event", zone_event)
			resolve(false)
		})
	}

	// ===================== Validaciones de formulario ===================
	validarCampo(campo: string) {
		return this.brickForm.get(campo).invalid &&
			this.brickForm.get(campo).touched
	}

	// ================== Permitir solo numeros ============================
	onlyNumbers(event: KeyboardEvent): boolean {
		const key = event.key;
		const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

		if (numbers.includes(key)) return true;
		return false;
	}


}

import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { }

  private ref = firebase.storage().ref();

  // Función para subir imagen delivery
  async uploadImageDelivery(imageFile: File){
    const fileName = `${uuid()}.png`;
    const imageRef = this.ref.child(`delivery/${fileName}`);
    let urlImage = '';

    await imageRef.put(imageFile).then(
      async () => {
        await imageRef.getDownloadURL().then(
          async response => urlImage = await response
        );
      }
    );

    return urlImage;
  }

  async uploadImages(idCategory: string, imageFile: File): Promise<any> {
    const fileName = `${uuid()}.png`;
    let tempRespOne: any;
    const imageRef = this.ref.child(`categories/${idCategory}/${fileName}`);
    
    await imageRef.put(imageFile).then(
      async response => {
        const data = response.metadata
        let { bucket, contentType, fullPath, md5Hash, size, updated } = data;

        tempRespOne = { bucket, contentType, fullPath, md5Hash, size, updated }

        await imageRef.getDownloadURL().then(
          async response => {
            tempRespOne.downloadURL = await response;
          }
        );
      }
    );

    return await tempRespOne;
  }

  async uploadImagesByPosition(idCategory: string, imageFile: File): Promise<any> {
    const fileName = `${uuid()}.png`;
    let tempRespOne: any;
    const imageRef = this.ref.child(`categories/${idCategory}/${fileName}`);
    
    await imageRef.put(imageFile).then(
      async response => {
        const data = response.metadata
        let { bucket, contentType, fullPath, md5Hash, size, updated } = data;

        tempRespOne = { bucket, contentType, fullPath, md5Hash, size, updated }

        await imageRef.getDownloadURL().then(
          async response => {
            tempRespOne.downloadURL = await response;
          }
        );
      }
    );

    return await tempRespOne['downloadURL'];
  }

  async uploadListImages(idCategory: string, imagesFiles: File[]) {
    const downloadUrl = [];
    let result = {};

    for await (const image of imagesFiles) {
      const fileName = `${uuid()}.png`;
      const imageRef = this.ref.child(`categories/${idCategory}/${fileName}`);
      await imageRef.put(image).then(
        response => {
          const { bucket, contentType, fullPath } = response.metadata;
          result['bucket'] = bucket;
          result['contentType'] = contentType;
          result['fullPath'] = fullPath
          imageRef.getDownloadURL().then(
            response => {
              downloadUrl.push(response);
            }
          );
        }
      )
    }

    result['downloadURL'] = downloadUrl;
    return result;
  }
}

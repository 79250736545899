export const RoleFourthDO = [
    {
        title: 'Monitor V2',
        url: '/index/monitor-v2',
        base: '',
        icon: 'notifications',
        alert: false
    },
    {
        title: 'Chats',
        url: '/index/chats',
        base: '',
        icon: 'chatbubbles',
        alert: false
    },
    {
        title: 'Dashboard',
        url: '/index/section/dashboard',
        base: '',
        icon: 'home',
        alert: false
    },
    {
        title: 'Buscador Producto',
        url: '/index/search-brick',
        base: '',
        icon: 'search-outline',
        alert: false
    },
    {
        title: 'Vinos',
        url: '/index/section/wines',
        base: '',
        icon: 'wine',
        customIcon: true,
        alert: false
    },
    {
        title: 'Destilados',
        url: '/index/section/liquors',
        base: '',
        icon: 'liquor',
        customIcon: true,
        alert: false
    },
    {
        title: 'Cervezas',
        url: '/index/section/beers',
        base: '',
        icon: 'beer',
        customIcon: true,
        alert: false
    },
    {
        title: 'No alcoholicas',
        url: '/index/section/non-alcoholic',
        base: '',
        icon: 'non-alcoholic',
        customIcon: true,
        alert: false
    },
    {
        title: 'Mixology',
        url: '/index/explore-section',
        base: '',
        icon: 'apps',
        alert: false
    },
    {
        title: 'Market',
        url: '/index/section/toeat',
        base: '',
        icon: 'toeat',
        customIcon: true,
        alert: false
    },
    {
        title: 'Para Fumar',
        url: '/index/section/digestic',
        base: '',
        icon: 'cigarrete',
        customIcon: true,
        alert: false
    },
    {
        title: 'Club Barlleno',
        url: '/index/section/club',
        base: '',
        icon: 'basket',
        alert: false
    },
    {
        title: 'Solicitudes de verificación',
        url: '/index/verified-requests',
        base: '',
        icon: 'archive-outline',
        alert: false
    },
    {
        title: 'Socios',
        url: '/index/verified',
        base: '',
        icon: 'star',
        alert: false
    },
    {
        title: 'Delivery',
        url: '/index/delivery',
        base: '',
        icon: 'bicycle-outline',
        alert: false
    },
    {
        title: 'Cumpleañeros del Mes',
        url: '/index/beerday',
        base: '',
        customIcon: true,
        icon: 'celebration',
        alert: false
    },
    {
        title: 'Notificaciones Push',
        url: '/index/push',
        base: '',
        icon: 'notifications-circle-outline',
        alert: false
    },
    {
        title: 'Cuentas',
        url: '/index/accounts',
        base: '',
        icon: 'people',
        alert: false
    },
    {
        title: 'Solicitudes de cuentas de negocios',
        url: '/index/business-accounts',
        base: '',
        icon: 'business-outline',
        alert: false
    },
    {
        title: 'Cuentas de negocios Verificadas',
        url: '/index/business-accounts-validate',
        base: '',
        customIcon: true,
        icon: 'profits',
        alert: false
    },
    {
        title: 'Cupones',
        url: '/index/coupons',
        base: '',
        icon: 'pricetags-outline',
        alert: false
    },
    {
        title: 'Configuraciones',
        url: '/index/settings',
        base: '',
        icon: 'cog',
        alert: false
    },
    {
        title: 'Asistencia Técnica',
        url: '/index/support',
        base: '',
        icon: 'build-outline',
        alert: false
    }
]
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-mappging-delivery',
    templateUrl: './mappging-delivery.component.html',
    styleUrls: ['./mappging-delivery.component.scss'],
})
export class MappgingDeliveryComponent implements OnInit {
    @Input() orders: any[];
    orderSelected :any
    sectionName = ""
    lat: any = 0
    long: any = 0

    icon = { url: 'assets/icons/ICONO-BARLLENO-UBICACION.svg', scaledSize: {height: 40, width: 40}}

    constructor(
        private api: ApiService,
        private auth: AuthService
    ) { }

    ngOnInit() { 
        this.orderSelected = this.orders[5]
        this.sectionName = this.orderSelected.name
        this.api.getDocument("stores", this.auth.storeKey).then((response:any)=>{
            let data = response
            this.lat = Number(data.lat != undefined ? data.lat : 0)
            this.long = Number(data.lng  != undefined ? data.lng :0)
        })
    }

    getLong ( element ) {
        return element[1]
    }
    getLat ( element ) {
        return element[0]
    }

    changeZone () {
        this.orderSelected = this.orders.find((item) => item.name == this.sectionName)
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-category-lupe-reyes',
  templateUrl: './add-category-lupe-reyes.component.html',
  styleUrls: ['./add-category-lupe-reyes.component.scss'],
})
export class AddCategoryLupeReyesComponent implements OnInit {
  categoryId: string = "";
  

  constructor() { }

  ngOnInit() {}

  addCategory(){

  }

}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-add-mixers',
  templateUrl: './add-mixers.component.html',
  styleUrls: ['./add-mixers.component.scss'],
})
export class AddMixersComponent implements OnInit {

  mixersOptionsA: any;
  mixers: any = {};
  mixersDefault: any = [];
  quantityMixers: any = 0;
  visible: any = false;
  loading: any = true;
  lastRequest: any;
  searching: any = false;
  GeneralInfo: any;
  index_config: any

  constructor(
    public api: ApiService,
    public modalController: ModalController,
    public navParams: NavParams,
    public auth: AuthService
  ) {
    if (this.auth.countryCode == 'SV') {
      this.index_config = 'general'
    } else {
      this.index_config = `${this.auth.countryCode}`
    }
    this.GeneralInfo = this.navParams.get('presentation');
    console.log(this.GeneralInfo);

  }

  ngOnInit() {

    this.getMixers()

  }

  async getMixers() {


    this.mixersOptionsA = this.GeneralInfo.mixersOptions;
    console.log(this.mixersOptionsA);

    this.mixers = this.mixersOptionsA.mixers;
    console.log(this.mixers);



  }

}
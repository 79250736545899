import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { NavParams, ModalController, LoadingController, IonInput } from '@ionic/angular';
import { BrickPreviewComponent } from '../brick-preview/brick-preview.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/services/database/orders.service';
import { SwalService } from 'src/app/services/alert/swal.service';
import { FunctionsService } from 'src/app/services/functions/functions.service';
import { BrickChangeComponent } from '../brick-change/brick-change.component';
import { SapService } from 'src/app/services/sap/sap.service';
import { CancelOrderComponent } from '../cancel-order/cancel-order.component';
import generateTicket from 'src/app/shared/ticket';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
    @ViewChild('numFactura', { static: false }) numFactura: IonInput;
    // @ViewChild(IonContent, null) txtPhone: any;
    phone: any
    options = [];
    zoneSelected: any;
    items: any
    order: any;
    amountSplit: any = 0
    isTotalClubFail: any = false
    isTotalPartnerFail: any = false;
    isTotalBarllenoFail: any = false;
    countProducts: any = 0
    referenceKits: any = []
    collectionsBrick: any = [];
    // condicionando visibilidad de opciones de edicion de orden 
    flagEditOrder: any = false
    lastnameColaborator: any = ''
    nameColaborator: any = ''
    nameDelivery: any = ''
    zoneName = ""
    oldNumberBill = ""
    aux: any = ""

    // multiples numeros de facturas
    codeBillArray: any = []
    flagBill = false
    codeBill: any
    flagBtnBill: boolean = false;
    saveBillAutomatic: boolean = true;
    deliveryKey: any = ''

    accountDelivery: any = []

    constructor(
        public api: ApiService,
        public sap: SapService,
        public navParams: NavParams,
        public modalController: ModalController,
        private zone: NgZone,
        public auth: AuthService,
        public loadingCtrl: LoadingController,
        private router: Router,
        private http: HttpClient,
        private ordersService: OrdersService,
        private SwalService: SwalService,
        private functionsService: FunctionsService
    ) {
        this.order = navParams.get('order');
        this.zoneSelected = navParams.get('zoneSelected');
        this.zoneName = navParams.get('zoneName');
        console.log(this.zoneSelected)


        this.options.unshift();
        // recuperando informacion de los Colaboradores (delivery)


        //filtrando las cuentas que tenga la propiedad delivery_active en true ESTO INDICA A LOS DELIVERYS ACTIVOS
        let filter: any[] = [
            {
                field: "delivery_active",
                comparison: '==',
                value: true
            }
        ]


        this.api.getCollection$(`accounts`, filter).subscribe(data => {

            if (data) {

                for (let i = 0; i < data.length; i++) {

                    this.accountDelivery.push(data[i]['$key']);
                    //almacenando las llaves de las cuentas que esta activas
                }
            }
        });

        this.api.getAllDocuments(`delivery`).then(data => {

            if (data) {
                data.forEach(element => {
                    //this.auth.countryCode == "DO" ||
                    if (element['countryCode'] == this.auth.countryCode && element['active'] == true &&  this.auth.countryCode == "GT") {
                        let arr = []
                        arr['id'] = element.$key ? element.$key : ''
                        arr['lastname'] = element.lastname ? element.lastname : ''
                        arr['name'] = element.name ? element.name : ''
                        arr['phonenumber'] = element.phonenumber ? element.phonenumber : ''
                        arr['userAccount'] = element.userAccount ? element.userAccount : ''

                        this.options.push(arr);

                    }

                    const result = this.accountDelivery.filter(item => item === element.userAccount)
                    //comparando que el usuario este activo como delivery en BarllenoGO 
                    if (result.some((key) => element['userAccount'] === key) && element['storeCode'] == this.auth.storeCode && (element.hasOwnProperty("active") && element['active'] == true) && element['userAccount'] != undefined && element['userAccount'] != "" && element['userAccount'] != null && (this.auth.countryCode == "HN" || this.auth.countryCode == "SV" || this.auth.countryCode == "RAF" || this.auth.countryCode == "DO" )) {

                        let arr = []
                        arr['id'] = element.$key ? element.$key : ''
                        arr['lastname'] = element.lastname ? element.lastname : ''
                        arr['name'] = element.name ? element.name : ''
                        arr['phonenumber'] = element.phonenumber ? element.phonenumber : ''
                        arr['userAccount'] = element.userAccount ? element.userAccount : ''
                      
                        this.options.push(arr);
                    }
                });
            }
        });

        if (this.order.hasOwnProperty("account_delivery")) {

            this.api.getDocument('accounts', this.order.account_delivery).then(data => {

                this.nameDelivery = data['name'] + " " + data['lastname']

            });
        }
    }

    ngOnDestroy() {
        if (this.oldNumberBill != "") this.order["idBill"] = this.oldNumberBill
    }


    async revert(order) {
        // Muestra el modal de entrada de texto de cancelacion de la orden
        const modalCancel = await this.modalController.create({
            component: CancelOrderComponent,
            cssClass: 'cancel-order',
            componentProps: {
                order: order,
                revertTransaction: true
            }
        });

        modalCancel.present()
    }

    onlyNumbers(event: any) {
        const charCode = (event.query) ? event.query : event.keyCode;
        return charCode >= 48 && charCode <= 57;
    }

    changeNumberBill(order) {
        this.oldNumberBill = order.idBill != undefined ? order.idBill : ""
        delete order.idBill
    }

    cancelChangeBill() {
        this.order["idBill"] = this.oldNumberBill
        this.oldNumberBill = ""
    }

    // Metodo para imprimir un ticket
    async printTicket(order) {
        let lbl =  this.zoneSelected.name.split(" ")
        let orderTicket = lbl.map((item) => item.charAt(0).toUpperCase())
        orderTicket += order.number
        
        let billedName = this.auth.email.split('@')[0]
        let products = order.bricks.map((item) => {
            return {
                description: item.brick.name,
                quantity: item.quantity,
                priceUnity: item.price.toFixed(2),
                totalLine: item.subtotal.toFixed(2)
            }
        })
        let typePayment = ""
        if (order.payment.type == "cash") typePayment = "Efectivo"
        if (order.payment.type == "card") typePayment = "Tarjeta"
        if (order.payment.type == "pos") typePayment = "POS"


        await generateTicket('print', {
            date: order.added_date.toLocaleString(),
            boxName: "Barlleno",
            numberTiket: orderTicket,
            billedBy: billedName, 
            products: products,
            total: order.total.toFixed(2),
            typePayment: typePayment,
        })
    }

    async guardarFactura(order: any) {

        if ((this.auth.storeCode !== "SV001" && this.auth.storeCode !== "SV002" && this.auth.countryCode !== "HN") || !this.saveBillAutomatic) {
            const resp = await this.ordersService.updateOrder(order.$key, this.numFactura.value.toLocaleString());

            order["idBill"] = this.numFactura.value.toLocaleString()
            this.order["idBill"] = this.numFactura.value.toLocaleString()
            if (resp) {
                this.order = await this.ordersService.getOrderById(order.number);
                this.numFactura.value = '';
                this.modalController.dismiss();
            }
        } else if (this.saveBillAutomatic) {
            let configuration: any = await this.api.getDocument("stores", this.auth.storeKey).then((response) => {
                return response
            })
            if ((order.hasOwnProperty("event_key") && order.event_key == 'iUOQFxFG201VIByJJcbL') || !order.hasOwnProperty("event_order") && (order.event_order == false || order.event_order == undefined) && configuration.hasOwnProperty("autoSaveBill") && configuration.autoSaveBill) {
                console.log("entroooo")
                if (this.oldNumberBill == "") {
                    let zoneCode = ""
                    if (order.address != false) {
                        console.log(this.zoneName)
                        if (this.zoneName.includes("ZONA 1")) {
                          zoneCode = "99-0001";
                        } else if (this.zoneName.includes("ZONA 2")) {
                          zoneCode = "99-0002";
                        } else if (this.zoneName.includes("ZONA 3")) {
                          zoneCode = "99-0003";
                        } else if (this.zoneName.includes("ZONA 4")) {
                          zoneCode = "99-0004";
                        } else if (this.zoneName.includes("ZONA 5")) {
                          zoneCode = "99-0005";
                        } else if (this.zoneName.includes("Cargo Express")) {
                          zoneCode = "99-0007";
                        } else {
                          this.mensajeAlerta(
                            "error",
                            "Zona de envio no valida"
                          );
                          return;
                        }
                    }
                    // let body = {
                    //     token: this.auth.token,
                    //     data: {
                    //         ...order,
                    //         codSucursal: this.auth.storeCode,
                    //         whareHouseCode: this.auth.codeCellar,
                    //         detailDelivered: zoneCode
                    //     }
                    // }
                    // this.loader().then(loader => {
                    //     loader.present().then(async () => {
                    //         let headers = new HttpHeaders()
                    //         this.sap.post("/service/orders/create/order/bill", body, headers).subscribe({
                    //             next: async (response: any) => {
                    //                 const resp = await this.ordersService.updateOrder(order.$key, response.data.numberBill);
                    //                 if (resp) {
                    //                     this.order = await this.ordersService.getOrderById(order.number);
                    //                     this.numFactura.value = response.data.numberBill;
                    //                     this.modalController.dismiss();
                    //                 }
                    //                 order["idBill"] = response.data.numberBill;
                    //                 loader.dismiss()
                    //             },
                    //             error: (error) => {
                    //                 this.mensajeAlerta('error', error.error.message);
                    //                 loader.dismiss()

                    //             }
                    //         })
                    //     })
                    // })
                } 
                // else {
                //     const resp = await this.ordersService.updateOrder(order.$key, this.numFactura.value.toLocaleString());
                //     order["idBill"] = this.numFactura.value.toLocaleString()
                //     this.order["idBill"] = this.numFactura.value.toLocaleString()
                //     if (resp) {
                //         await this.ordersService.getOrderById(order.number);
                //         this.numFactura.value = '';
                //         this.modalController.dismiss();
                //     }
                // }
            } else {
                const resp = await this.ordersService.updateOrder(order.$key, this.numFactura.value.toLocaleString());
                if (resp) {
                    this.order = await this.ordersService.getOrderById(order.number);
                    order["idBill"] = this.numFactura.value.toLocaleString()
                    this.order["idBill"] = this.numFactura.value.toLocaleString()
                    this.numFactura.value = '';
                    this.modalController.dismiss();
                }
            }

        }
    }

    async saveNumbersBill(order) {
        this.flagBtnBill = true

        this.SwalService.fire({
            title: 'Estás seguro Guardar?',
            text: "¡No podrás revertir esto!",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro'
        }).then(async (result) => {
            if (result.value) {
                await this.api.getDocument('orders', order.$key).then(async data => {
                    if (data) {
                        let idBillOld = data['idBill'] != undefined ? data['idBill'] : []
                        this.codeBillArray = [...new Set(this.codeBillArray.concat(idBillOld))]

                        this.api.updateDocument('orders', order.$key, {
                            idBill: this.codeBillArray
                        }).then(data => {
                            this.mensajeAlerta('success', 'Números de Facturas. Guardados con exito.');
                        })
                    }
                });

            }
        })
    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

    suspectClient(account: any){

        console.log(account);
        
        this.SwalService.fire({
            title: 'Estás seguro de marcar como sospechoso?',
            text: "¡No podrás revertir esto!",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro'
        }).then(async (result) => {
            if (result.value) {
                await this.api.updateDocument('accounts', account.$key, {
                    suspect: true,
                    suspectDetails: {
                        date: new Date(),
                        monitorUser: this.auth.email
                    }
                }).then(data => {
                    this.mensajeAlerta('success', 'Cliente marcado como sospechoso.');
                })
            }
        })
    }

    setDelivery(item) {
        this.phone = item.phonenumber
        this.lastnameColaborator = item.lastname
        this.nameColaborator = item.name
        this.deliveryKey = item.userAccount
        this.saveDelivery(this.nameColaborator, this.lastnameColaborator, this.deliveryKey)
        
    }

    async saveDelivery(deliveryName, deliveryLastname, deliveryKey) {
        //&& this.auth.countryCode != 'DO'
        if (deliveryKey == undefined || deliveryKey == null || deliveryKey == ''  && this.auth.countryCode != 'GT') {
            this.mensajeAlerta('error', 'No se puede asignar delivery a este pedido.');
            return
        }
        this.SwalService.fire({
            title: `Estás seguro de asignar delivery a  ${deliveryName + "" + deliveryLastname} ?`,
            text: "¡No podrás revertir esto!",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, estoy seguro'
        }).then(async (result) => {
            if (result.value) {
                if ( this.auth.countryCode == 'RAF' || this.auth.countryCode == 'DO' ) {
                    if ( !this.order.storeZoneInventary ) {
                        this.api.decrementInventory(this.order.bricks, this.auth.sector, this.order.$key);
                      }
                }
                await this.api.updateDocument('orders', this.order.$key, {
                    account_delivery: deliveryKey,
                    assignate_delivery_time: new Date()
                }).then(data => {
                    this.mensajeAlerta('success', 'Delivery. Guardado con exito.');
                    this.nameDelivery = deliveryName + " " + deliveryLastname
                })
            }
        })
    }


    changeCalled(type) {
        if (this.phone) {
            let nameFull = '' + this.order.account.lastname + ' ' + this.order.account.name
            openInNewTab(type, this.phone, this.order.address.lat, this.order.address.lng, nameFull, this.order.address.location, this.order.address.houseDetail, this.order.address.landMark, this.order.payment.type, this.nameColaborator, this.lastnameColaborator)
        } else {
            this.SwalService.fire(
                'Seleccione Datos de Delivery',
                'Registre en Menu -> Delivery. Los datos #CodigoPais + Telefono',
                'warning'
            )
        }
    }

    async ngOnInit() {
                
        await this.getInfoOrder()
    }

    ionViewWillEnter() {
        this.forceUpdate();
    }

    forceUpdate() {
        this.zone.run(() => { });
    }

    async seeBrick(brick) {
        const modal = await this.modalController.create({
            component: BrickPreviewComponent,
            cssClass: 'addModal',
            componentProps: {
                brick: brick.brick
            }
        });
        return await modal.present();
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    captureSplit(split) {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Aceptar la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=split-${split.$key}&amount=${split.amount}&type=1`;
                        let url;

                        url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

                        this.http.get(url)
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1) {

                                    this.api.updateDocument('splits', split.$key, {
                                        'transaction_status': 'captured',
                                    }).then(data => {
                                        split.transaction_status = 'captured';
                                        this.SwalService.fire(
                                            'Transacción Capturada',
                                            'Captura de fondos realizar con exito',
                                            'success'
                                        )
                                    })

                                    this.SwalService.fire(
                                        'Transacción Capturada',
                                        'Captura de fondos realizar con exito',
                                        'success'
                                    )
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo completar la captura de la transacción, comunicate con el equipo de barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    } else {
                        loader.dismiss();
                    }
                });
            })
        })
    }

    capture(order) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
            })
        };

        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Aceptar la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {

                    if (result.value) {
                        // let getData = `orderNo=${order.number}&amount=${order.total}&type=1`;
                        let getData = `orderNo=${order.number}&split-${order.$key}&amount=${order.total}&type=1`;
                        let transaction_id = order.transaction_id;
                        let url;
                        if (this.auth.countryCode == 'SV') {
                            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

                        } else if (this.auth.countryCode == 'GT') {
                            url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
                        } else if (this.auth.countryCode == 'CO') {
                            url = ((`https://production.wompi.co/v1/transactions/${transaction_id}`));
                        }

                        this.api.updateDocument('orders', order.$key, {
                            'assignateTo': this.auth.namePerson
                        })
                        if (this.auth.countryCode !== 'CO') {
                            this.http.get(url)
                                .subscribe((response) => {
                                    if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                                        this.api.updateDocument('orders', order.$key, {
                                            'transaction_status': 'captured',
                                            'status': 'making'
                                        }).then(data => {
                                            order.transaction_status = 'captured';
                                            this.SwalService.fire(
                                                'Transacción Capturada',
                                                'Captura de fondos realizar con exito',
                                                'success'
                                            )
                                        })
                                    } else {
                                        this.SwalService.fire(
                                            'Error!',
                                            'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
                                            'error'
                                        )
                                    }
                                    loader.dismiss();
                                    this.forceUpdate();
                                    this.router.navigate(['/monitor'])
                                }, err => {
                                    loader.dismiss();
                                });
                        }


                        else {
                            this.http.get(url, httpOptions)
                                .subscribe((response) => {
                                    if (response['data']['status'] == 'APPROVED') {
                                        this.api.updateDocument('orders', order.$key, {
                                            'transaction_status': 'captured',
                                            'status': 'making'
                                        }).then(data => {
                                            order.transaction_status = 'captured';
                                            this.SwalService.fire(
                                                'Transacción Capturada',
                                                'Captura de fondos realizar con exito',
                                                'success'
                                            )
                                        })
                                    } else {
                                        this.SwalService.fire(
                                            'Error!',
                                            'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
                                            'error'
                                        )
                                    }
                                    loader.dismiss();
                                }, err => {
                                    loader.dismiss();
                                });
                        }

                    } else {
                        loader.dismiss();
                    }
                });
            })
        })
    }

    revertSplit(split) {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Revertir la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=split-${split.$key}&amount=${split.amount}&type=3`;
                        this.http.get(`https://payments.barlleno.app/transactionModification.php?${getData}`)
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                                    this.api.updateDocument('splits', split.$key, {
                                        'transaction_status': 'Reverted',
                                    }).then(data => {
                                        split.transaction_status = 'Reverted';
                                        this.SwalService.fire(
                                            'Transacción Capturada',
                                            'Captura de fondos realizar con exito',
                                            'success'
                                        )
                                    })
                                    this.SwalService.fire(
                                        'Transacción Revertida',
                                        'Reversión de fondos realizar con exito',
                                        'success'
                                    )
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo revertir la transacción, comunicate con el equipo de barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    }
                    else {
                        loader.dismiss();
                    }
                })
            })
        })
    }

    captureOrder() {
        this.loader().then(loader => {
            loader.present().then(() => {

                this.SwalService.fire({
                    title: 'Esta seguro que deseas Aceptar la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=${this.order.number}&amount=${this.order.total}&type=1`;
                        let url;

                        url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

                        this.http.get(url)
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                                    this.api.updateDocument('orders', this.order.$key, {
                                        'transaction_status': 'captured',
                                        'status': 'making'
                                    }).then(data => {
                                        this.order.transaction_status = 'captured';
                                        this.SwalService.fire(
                                            'Transacción Capturada',
                                            'Captura de fondos realizar con exito',
                                            'success'
                                        )
                                    })
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo completar la captura de la transacción, comunicate con el equipo de barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    } else {
                        loader.dismiss();
                    }
                });
            })
        })
    }

    revertOrder() {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.SwalService.fire({
                    title: 'Esta seguro que deseas Revertir la transferencia?',
                    text: "¡No podrás revertir esto!",
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, estoy seguro'
                }).then((result) => {
                    if (result.value) {
                        let getData = `orderNo=${this.order.number}&amount=${this.order.total}&type=3`;
                        this.http.get(`https://payments.barlleno.app/transactionModification.php?${getData}`)
                            .subscribe((response) => {
                                if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                                    this.api.updateDocument('orders', this.order.$key, {
                                        'transaction_status': 'Reverted',
                                        'status': 'canceled'

                                    }).then(data => {
                                        this.order.transaction_status = 'Reverted';
                                        this.SwalService.fire(
                                            'Transacción Revertida',
                                            'Reversión de fondos realizar con exito',
                                            'success'
                                        )
                                    })
                                } else {
                                    this.SwalService.fire(
                                        'Error!',
                                        'No se pudo revertir la transacción, comunicate con el equipo de barlleno',
                                        'error'
                                    )
                                }
                                loader.dismiss();
                            }, err => {
                                loader.dismiss();
                            });
                    }
                    else {
                        loader.dismiss();
                    }
                })
            })
        })
    }

    sendEmailRequest() {
        this.loader().then(loader => {
            loader.present().then(async () => {
                await this.http.get(`https://mails.barlleno.app/mails_inventoryTest.php?mode=notification_zendesk_order_fail&email=${window.btoa(JSON.stringify(this.auth.email))}&countryCode=${window.btoa(JSON.stringify(this.auth.countryCode))}&created_at=${window.btoa(JSON.stringify(new Date()))}&role=${this.auth.role}&order=${window.btoa(JSON.stringify(this.order))}`)
                    .subscribe((response) => {
                        loader.dismiss();
                        this.SwalService.fire('¡Listo!', 'Se ha enviado con éxito solicitud de Asistencia Técnica.', 'success');
                    })
            })
        })
    }

    orderEmail(id: string) {
        this.loader().then(loader => {
            loader.present().then(() => {
                this.functionsService.sendEmail(id)
                    .then(() => {
                        loader.dismiss();
                        this.SwalService.fire(
                            'Correo envíado',
                            'Se envió con con éxito',
                            'success'
                        )
                    })
                    .catch((err) => {
                        loader.dismiss();
                        this.SwalService.fire(
                            'Error',
                            'No se pudo enviar el correo',
                            'error'
                        )
                    })

            })
        })
    }

    // componente para intercambiar productos en monitor barlleno
    async changeBrick(order, brick, isModal = true) {
        // logs de intercambio
        let flagFist = false
        if (!order.bricks_exchange && !order.bricks_original) {
            order['bricks_exchange'] = [brick] // .push(brick)
            // guardando primera instantanea del brick en base bricks_original
            order['bricks_original'] = [brick]
            flagFist = true
        }
        if (!order.bricks_exchange) {
            order['bricks_exchange'] = [brick] // .push(brick)
        } else {
            if (!order['bricks_exchange'].find(element => element.$key == brick.$key)) {
                order['bricks_exchange'].push(brick)
            }
        }

        if (flagFist) {
            this.api.updateDocument('orders', order.$key, {
                bricks_exchange: order['bricks_exchange'],
                bricks_original: order['bricks_original']
            }).then(data => {
            })
        } else {
            this.api.updateDocument('orders', order.$key, {
                bricks_exchange: order['bricks_exchange']
            }).then(data => {
            })
        }

        if (isModal == false) {
            this.deleteChangeBrick(brick, order.number, brick.brick)
        }

        if (isModal) {
            const modal = await this.modalController.create({
                component: BrickChangeComponent,
                cssClass: 'addModal',
                componentProps: {
                    brick_delete: brick.brick,
                    number_order: order.number
                }
            });
            modal.onDidDismiss().then(async data => {
                // window.location.reload();
                await this.api.getDocument('orders', order.$key).then(async data => {
                    this.order = data;
                    let response = await this.getInfoOrder()
                })
            })

            return await modal.present();
        }
    }
    // eliminando brick de la orden funcion aux para respaldar los datos originales
    deleteChangeBrick(brick, number_order, brick_delete) {

        let bricksUpdate = []
        if (number_order) {
            this.loader().then(loader => {
                loader.present().then(() => {
                    this.api.getRef('orders').ref
                        .where('number', '==', number_order)
                        .get()
                        .then(async snapshots => {
                            if (snapshots.size > 0) {
                                const { docs } = snapshots
                                let { bricks } = docs[0].data();
                                let keyOrder = docs[0].id
                                // procedo a eliminar el brick que estoy reemplazando
                                for (let index = 0; index < bricks.length; index++) {
                                    let element = bricks[index];
                                    if (element.brick_key == brick_delete.$key) {
                                        delete bricks[index]
                                        // break
                                    } else {
                                        bricksUpdate.push(bricks[index])
                                    }
                                }

                                await this.api.updateDocument('orders', keyOrder, {
                                    bricks: bricksUpdate
                                }).then(async data => {
                                    await this.api.getDocument('orders', keyOrder).then(async data => {
                                        this.order = data;
                                        await this.getInfoOrder()
                                    })
                                    loader.dismiss();
                                    // window.location.reload();

                                })
                            }
                        })
                })
            })
        }
        // loader.dismiss();
        // window.location.reload();

    }

    getInfoOrder() {
        let costs = 0;
        return new Promise((resolve, reject) => {
            console.log('acaa',this.order);

            if (typeof this.order.account === 'string') {
                this.api.getDocument('accounts', this.order.account).then(data => {
                    this.order.account = data;
                })
            }
            if (this.order.hasOwnProperty('event_brick_table') && typeof this.order.event_brick_table === 'string') {
                this.api.getDocument('bricks', this.order.event_brick_table).then(data => {
                    this.order.event_brick_table = data;
                })
            } else if (this.order.hasOwnProperty('event_category_key')) {
                this.api.getDocument('bricks', this.order.event_category_key).then(data => {
                    this.order.event_brick_table = data;
                })
            }
            if (typeof this.order.added_date === 'object') {
                // this.order.added_date = this.order.added_date.toDate()
            }
            if (this.order.splits) {
                this.order.splits.forEach(element => {

                    this.amountSplit += element.amount

                    this.api.getDocument('accounts', element.friend).then(data => {
                        element.friend = data;
                    })
                });
            }







            if (this.order.costAplicated != undefined) {
                this.order.costAplicated.forEach((element: any) => {
                  costs += element.amount;
                });
            }

            




            let countPartnerEstrellas = 0
            let checkClubBarlleno = 0
            let checkBarlleno = 0
            this.order.supplier_partner = []
            this.order['bricks'].forEach(element => {
                if (element['brick'].hasOwnProperty('partner') && element['brick'].partner == true && element['type'] == 'club') {
                    countPartnerEstrellas = countPartnerEstrellas + (Number(element['quantity']) * Number(element['price']))

                    if (element['brick'].supplier_partner != undefined && element['brick'].supplier_partner != '') this.order.supplier_partner.push(element['brick'].supplier_partner)
                }
                // iniciando el check de los puntos barlleno de cada uno de los bricks
                if (element.type == 'club' && !element['brick'].hasOwnProperty('partner')) {
                    checkClubBarlleno = checkClubBarlleno + (Number(element.price) * Number(element.quantity))
                }
                if (element.type == 'brick') {
                    checkBarlleno = checkBarlleno + (Number(element.price) * Number(element.quantity))                    
                }
                // agregando los detalles de las productos asosciados
                if (element['brick'].hasOwnProperty('referenceKits')) {
                    this.countProducts = (element.brick.referenceKits.length * element.quantity) + this.countProducts
                    for (let index = 0; index < element.brick.referenceKits.length; index++) {
                        const item = element.brick.referenceKits[index];
                        this.api.getDocument('bricks', item.reference).then(data => {
                            item['Info'] = data;
                            this.collectionsBrick.push({ name: item['Info']['name'] })
                        })
                    }
                }
            });
            // checkBarlleno += this.order.cost_admin != undefined ? this.order.cost_admin : 0       
            // calculo de estrellas
            if (countPartnerEstrellas > 0) this.order.total_points_partner == countPartnerEstrellas ? this.isTotalPartnerFail = false : this.isTotalPartnerFail = true
            // this.order.partner = true
            // this.order.total_points_partner = countPartnerEstrellas

            // calculo de puntos club barlleno
            if (checkClubBarlleno > 0) checkClubBarlleno == this.order.total_points ? this.isTotalClubFail = false : this.isTotalClubFail = true
            checkBarlleno += Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0)


            // calculo de barlleno

            console.log(checkBarlleno.toFixed(2),"--->",(Number(this.order.total > 0 ? this.order.total : 0)
            + Number(this.order.supplier_discount_total > 0 ? this.order.supplier_discount_total : 0)
            - Number(this.order.supplier_discount_not_applied > 0 ? this.order.supplier_discount_not_applied : 0)
            + Number(this.order.second_round_discount > 0 ? this.order.second_round_discount : 0)
            + Number(this.order.bin_cant_discount > 0 ? this.order.bin_cant_discount : 0)
            + Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0)
            - Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0)
            - Number(this.order.tip > 0 ? this.order.tip : 0)
            - Number(this.order.gift_price > 0 ? this.order.gift_price : 0)
            + Number(this.amountSplit > 0 ? this.amountSplit : 0)
            + Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0)
            - Number(this.order.cost_admin > 0 ? this.order.cost_admin : 0) - costs
        ).toFixed(2))


            if (checkBarlleno > 0) checkBarlleno.toFixed(2) == (Number(this.order.total > 0 ? this.order.total : 0)
                + Number(this.order.supplier_discount_total > 0 ? this.order.supplier_discount_total : 0)
                - Number(this.order.supplier_discount_not_applied > 0 ? this.order.supplier_discount_not_applied : 0)
                + Number(this.order.second_round_discount > 0 ? this.order.second_round_discount : 0)
                + Number(this.order.bin_cant_discount > 0 ? this.order.bin_cant_discount : 0)
                + Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0)
                - Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0)
                - Number(this.order.tip > 0 ? this.order.tip : 0)
                - Number(this.order.gift_price > 0 ? this.order.gift_price : 0)
                + Number(this.amountSplit > 0 ? this.amountSplit : 0)
                + Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0)
                - Number(this.order.cost_admin > 0 ? this.order.cost_admin : 0) - Number(costs)
            ).toFixed(2)

                ? this.isTotalBarllenoFail = false : this.isTotalBarllenoFail = true
            this.order['supplier_discount_total'] > 0 ? this.order['supplier_discount_total'] : this.order['supplier_discount_total'] = 0
            this.order['supplier_discount_not_applied'] > 0 ? this.order['supplier_discount_not_applied'] : this.order['supplier_discount_not_applied'] = 0
            this.order['second_round_discount'] > 0 ? this.order['second_round_discount'] : this.order['second_round_discount'] = 0
            this.order['all_store_discount'] > 0 ? this.order['all_store_discount'] : this.order['all_store_discount'] = 0

            resolve('success')
        });
        
    }

    // addBrickOrder agregando nuevos productos a la orden actual
    async addBrickOrder(order, isModal = true) {
        if (isModal) {
            const modal = await this.modalController.create({
                component: BrickChangeComponent,
                cssClass: 'addModal',
                componentProps: {
                    number_order: order.number,
                    order: order
                }
            });

            modal.onDidDismiss().then(async data => {
                //     window.location.reload();
                await this.api.getDocument('orders', order.$key).then(async data => {
                    this.order = data;
                    await this.getInfoOrder()
                })
            })
            return await modal.present();
        }
    }

    // recalculando totales de la orden
    updateTotalOrder(order) {
        // return 
        this.loader().then(loader => {
            loader.present().then(() => {
                if (order.payment.type == 'cash') {
                    Number(order.tip) > 0 ? order.tip = Number(order.tip) : order.tip = 0
                    Number(order.total_delivery) > 0 ? order.total_delivery = Number(order.total_delivery) : order.total_delivery = 0

                    let totalSuma = 0
                    for (let i = 0; i < order.bricks.length; i++) {
                        totalSuma = totalSuma + order.bricks[i].subtotal
                    }
                    let total = totalSuma > 0 ? order.total_cart = totalSuma : order.total_cart = 0
                    total = total + order.tip + order.total_delivery + order.gift_price
                    order.change = order.change > total ? order.change : total
                    this.api.updateDocument('orders', order.$key, {
                        'total': total,
                        'change': order.change,
                        'brick_change': true
                    }).then(async data => {
                        await this.api.getDocument('orders', order.$key).then(async data => {
                            this.order = data;
                            this.SwalService.fire(
                                'Orden Actualizada',
                                'Totales actualizados',
                                'success'
                            )
                            await this.notificationsOff()
                        })
                        loader.dismiss();
                        // window.location.reload();
                    })
                } else if (order.payment.type == 'card') {
                    if (order.total_payment_card) {
                        order.total = order.total_payment_card
                    }
                    order['total_payment_card'] = order.total

                    Number(order.tip) > 0 ? order.tip = Number(order.tip) : order.tip = 0
                    Number(order.total_delivery) > 0 ? order.total_delivery = Number(order.total_delivery) : order.total_delivery = 0

                    let totalSuma = 0
                    for (let i = 0; i < order.bricks.length; i++) {
                        totalSuma = totalSuma + order.bricks[i].subtotal
                    }
                    order['total_payment_cash'] = 0
                    if (order.total_payment_cash < 0) {
                        this.SwalService.fire(
                            'BARLLENO',
                            `ORDEN ${order.number} Consumo en Orden Tienen que ser mayor a  ${order.total}`,
                            'warning'
                        )
                        loader.dismiss();
                        window.location.reload();
                        return
                    }

                    let total = totalSuma > 0 ? order.total_cart = totalSuma : order.total_cart = 0
                    total = total + order.tip + order.total_delivery + order.gift_price
                    order.change = 0
                    order.total_payment_cash = total - order.total_payment_card  // lo pagado con tarjeta - nuevo total
                    this.api.updateDocument('orders', order.$key, {
                        'total': total,
                        'change': order.change,
                        'brick_change': true,
                        'total_payment_card': order.total_payment_card,
                        'total_payment_cash': order.total_payment_cash
                    }).then(async data => {

                        await this.api.getDocument('orders', order.$key).then(async data => {
                            this.order = data;
                            this.SwalService.fire(
                                'Orden Actualizada',
                                'Totales actualizados',
                                'success'
                            )
                            await this.notificationsOff()

                        })
                        loader.dismiss();
                        // window.location.reload();
                    })
                }
            })
        })
    }

    async notificationsOff() {
        // this.isTotalClubFail = false
        // this.isTotalPartnerFail = false
        // this.isTotalBarllenoFail = false
        await this.getInfoOrder()
    }

    booleanEditOrderProducts() {
        this.flagEditOrder = true
    }

    // manejo de multiples numeros de facturas
    addCodeBill() {
        this.flagBill = true
        this.codeBillArray.push(this.codeBill)
        this.codeBill = ''
        this.flagBtnBill = false
    }

    changeStatusService(order, status, gift = false) {
        return new Promise(async (resolve, reject) => {
            let respService = await this.functionsService.changeStatus(order, status)
            resolve('success')
        });
    }

    captureService(order) {
        return new Promise(async (resolve, reject) => {
            let respCaptureService = await this.functionsService.capture(order)
            await this.api.updateDocument('orders', order.$key, {
                'assignateTo': this.auth.namePerson
            })
            resolve('success')
        });
    }


    captureCashService(order) {
        return new Promise(async (resolve, reject) => {
            let respCaptureCashService = await this.functionsService.captureCash(order)
            resolve('success')
        });
    }

    public closeModal = async () => {
        await this.modalController.dismiss();
    }

    // enviando correo con e-ticket
    async changeTickets(order, brick_name = 'E-Ticket') {
        this.SwalService.fire({
            title: `Esta seguro que desea Enviar CÓDIGO QR?`,
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
        }).then(async (result) => {
            if (result.value) {
                if (order.hasOwnProperty('number_original')) {
                } else if (!order.hasOwnProperty('number_original')) {

                    // evaluando si la original contiene viernes y sabado // multiples fechas
                    let aumentador: any = 0
                    // evaluando multiples bricks tipo = tickets en orden
                    const busqueda = order['bricks'].reduce((acc, brick) => {
                        if (brick.presentation.indexOf('TICKET')) {
                            acc[brick.presentation] = ++acc[brick.presentation] || 0;
                            return acc;
                        } else return 0
                    }, {});

                    const duplicados = order['bricks'].filter((brick) => {
                        return busqueda[brick.presentation];
                    });
                    if (duplicados.length > 0) {
                        aumentador = 1
                    }
                    // fin evaluacion del contenido de la orden
                    if (!(aumentador > 0)) {
                        // revisando los bricks dentro de la orden 
                        let responseDualTicket = false
                        for (let index = 0; index < order['bricks'].length; index++) {
                            let element = order['bricks'][index];
                            let isSabado = element['brick']['name'].toUpperCase().indexOf('bado'.toUpperCase())
                            let isViernes = element['brick']['name'].toUpperCase().indexOf('Viernes'.toUpperCase())
                            let isKit = element['brick']['name'].toUpperCase().indexOf('KIT'.toUpperCase())
                            if (isSabado > 0 && isViernes > 0) {
                                responseDualTicket = true
                            }
                            if (isKit >= 0) {
                                isSabado = element['presentation'].toUpperCase().indexOf('bado'.toUpperCase())
                                isViernes = element['presentation'].toUpperCase().indexOf('Viernes'.toUpperCase())
                                if ((isKit >= 0 && isSabado > 0) || (isKit >= 0 && isViernes > 0)) {
                                    responseDualTicket = true
                                    await this.api.updateDocument('orders', order.$key, {
                                        splitETicketSendEmail: true,
                                    })
                                }
                            } else {
                                responseDualTicket = false
                            }
                        }
                        // no es dual fechas el ticket
                        if (responseDualTicket == false) {
                            // >>> enviando correo original
                            let respEmail = await this.initSendEmail('number', order.number, order.account)
                        }
                    } else {
                        await this.api.updateDocument('orders', order.$key, {
                            splitETicketSendEmail: true,
                        })
                    }
                    if (order.hasOwnProperty('splitETicketMulti') && order.splitETicketMulti == true) {
                        // estoy con una orden que SI fue dividida
                        // >>> enviando correos a clones
                        let respEmail = await this.initSendEmail('number_original', order.number, order.account)

                    } else if (order.hasOwnProperty('splitETicketMulti') && order.splitETicketMulti == false) {
                        // estoy con una orden que NO fue dividida
                        // >>> no enviar correo
                    }
                }
            }
        })
    }

    initSendEmail(clave, valor, account) {
        return new Promise(async (resolve, reject) => {
            await this.api.getRef('orders').ref
                .where(clave, "==", valor)
                .get()
                .then(async snapshots => {
                    if (snapshots.size > 0) {
                        for (let i = 0; i < snapshots.size; i++) {
                            let doc = snapshots.docs[i]
                            let orderInfo = doc.data();
                            orderInfo.$key = doc.id;
                            let respSAP = await this.sap.getPostsTicketevent(this.auth.countryCode, orderInfo.number, orderInfo.number, orderInfo.$key, null)
                            this.SwalService.fire(
                                'Correo envíado',
                                `Respuesta de mensajeria es: ${respSAP['message']}`,
                                'success'
                            )
                        }
                        resolve('success')
                    } else {
                        resolve('success')
                    }
                })
        });
    }


    validateSupplierDiscount(order) {
        let flagSupplier = false
        if (order.hasOwnProperty("supplier_discount")) {
            for (let i = 0; i < order.supplier_discount.length; i++) {
                if (order.supplier_discount[i].hasOwnProperty("type") && order.supplier_discount[i].type.includes("supplier_discount")) {
                    flagSupplier = true
                }
            }
        }
        return !(order.hasOwnProperty("supplier_discount") && flagSupplier)
    }
}

function openInNewTab(type, phone, lat, lng, name = '', location = '', houseDetail = '', landMark = '', paymentType = '', nameColaborator = '', lastnameColaborator = '') {
    landMark = replaceAll(landMark, "#", 'n').trim()
    location = replaceAll(location, '#', 'n').trim()
    houseDetail = replaceAll(houseDetail, '#', 'n').trim()
    nameColaborator = nameColaborator.trim()
    name = name.trim()
    // apoyo en https://www.wati.io/free-whatsapp-link-generator/ generar el contenido dinamico
    if (type == 'google') {
        // google
        // var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola,%20Esta%20es%20la%20localizacion%20del%20cliente%20${name}%20%20https://www.google.com.ar/maps/place/${lat},${lng}`, '_blank');
        var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola%20*${nameColaborator}*%2C%20tienes%20un%20pedido%20asignado%20para%20*${name}*%0A%0ALa%20direcci%C3%B3n%20es%20*${location}*.%20La%20casa%2FPiso%20tiene%20numero.%20*${houseDetail}*%20%0A%0ALa%20referencia%20*${landMark}*%20%0A%0AEl%20pago%20fue%20en%20*${paymentType}*%20y%20las%20coordenadas%20son%20https://www.google.com.ar/maps/place/${lat},${lng}`, '_blank');
        win.focus();
    } else {
        // waze
        // var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola,%20Esta%20es%20la%20localizacion%20del%20cliente%20${name}%20%20https://waze.com/ul?ll=${lat},${lng}&z=10`, '_blank');
        // var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola%20*${nameColaborator}*,%20tienes%20un%20pedido%20asignado%20para%20*${name}*%20la%20dirección%20es%20*${location}*.%20La%20casa/Piso%20tiene%20numero.%20*${houseDetail}*%20la%20referencia%20*${landMark}*%20,%20el%20pago%20fue%20en%20*${paymentType}*%20y%20las%20coordenadas%20son%20https://waze.com/ul?ll=${lat},${lng}&z=10`, '_blank');
        var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola%20*${nameColaborator}*%2C%20tienes%20un%20pedido%20asignado%20para%20*${name}*%0A%0ALa%20direcci%C3%B3n%20es%20*${location}*.%20La%20casa%2FPiso%20tiene%20numero.%20*${houseDetail}*%20%0A%0ALa%20referencia%20*${landMark}*%20%0A%0AEl%20pago%20fue%20en%20*${paymentType}*%20y%20las%20coordenadas%20son%20https://waze.com/ul?ll=${lat},${lng}&z=10`, '_blank');
        win.focus();
    }
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}
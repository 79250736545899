import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
    selector: 'app-add-coupon',
    templateUrl: './add-coupon.component.html',
    styleUrls: ['./add-coupon.component.scss'],
})
export class AddCouponComponent implements OnInit {

    coupon: any;
    orders: any = [];
    couponForm: FormGroup;
    unlimited: any = false;
    total_orders: any = 0;
    total: any = 0;

    constructor(
        public api: ApiService,
        public auth: AuthService,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        private http: HttpClient,
        public formBuilder: FormBuilder
    ) {
        this.coupon = navParams.get('couponName');

        this.unlimited = false;

        this.couponForm = this.formBuilder.group({
            code: [this.coupon, [Validators.required]],
            available: [false, [Validators.required]],
            available_all_users: [false, [Validators.required]],
            type: ['free_delivery', [Validators.required]],
            uses: [1, [Validators.required]],
            countryCode: [this.auth.countryCode, [Validators.required]]
        });
    }

    ngOnInit() { }

    changeUnlimited(e) {
        if (e.detail.checked) {
            this.unlimited = true;
        } else {
            this.unlimited = false;
        }
    }

    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }


    addCoupon() {
        let data = this.couponForm.value;
        //console.log(data);

        if (this.unlimited) {
            data.uses = {
                unlimited: true
            };
        }

        if (data.code === '' || data.code == undefined) {
            this.mensajeAlerta('error', 'Favor completar Nombre de Cupon');
            return;
        } else {
            data.code = data.code.toUpperCase()
        }
        this.api.getRef('coupons').ref
            .where('countryCode', '==', this.auth.countryCode)
            .where('code', '==', data.code)
            .get()
            .then(snippets => {
                let element_size = snippets.size
                if (element_size > 0) {
                    this.mensajeAlerta('error', 'Codigo Cupon ya existe en Barlleno');
                    return;
                } else {
                    this.api.addDocument('coupons', data).then(response => {
                        this.api.addDocument(`coupons/${response.id}/history`,{
                            created: new Date(),
                            email: this.auth.email,
                            action: "addedCoupon",
                            data: data
                        })
                        this.modalController.dismiss();
                    })
                }
            })
    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

}

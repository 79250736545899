import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { SapService } from 'src/app/services/sap/sap.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { AddReferenceBanerComponent } from '../add-reference-baner/add-reference-baner.component';

@Component({
	selector: 'app-banner-create',
	templateUrl: './banner-create.component.html',
	styleUrls: ['./banner-create.component.scss'],
})
export class BannerCreateComponent implements OnInit {
	categoryData: any[] = []
    imagesBanner: any[] = []
    categoryBanner: any[] = []
    image:any
    detailImageFile = []
    name = ""
    storekey = ""
    visibleStatus: boolean = true
    reference = []

	constructor(
		public navParams: NavParams,
        public modalController: ModalController,
        public loadingCtrl: LoadingController,
        public api: ApiService
	) {
		this.categoryData = navParams.get('categories');
        this.storekey = navParams.get('store');
	}

	ngOnInit() { }


    moveToCategory(type: string, item:any) {
        if ( type == 'add' ) {
            if ( this.categoryBanner.length < 3 ) {
                let filter = this.categoryBanner.find(e => e.$key == item.$key )
                this.categoryData = this.categoryData.filter(e => e.$key != item.$key )

                if ( filter == undefined) {
                    this.categoryBanner.push(item)
                } else {
                    this.modalMessage('Categoria ya agregada, selecciona otra','warning')
                }
            } else {
                this.modalMessage('Solo se pueden configurar maximo de 3 categorias por banner','warning')

            }
        } else if ( type == 'delete' ) {
            let element = this.categoryBanner.find(e => e.$key == item.$key )
            this.categoryBanner = this.categoryBanner.filter(e => e.$key != item.$key )
            this.categoryData.push(element)
        }
    }

    openExplorer () {
        if ( this.detailImageFile.length < 3){
            const fileInput = document.getElementById('image-input') as HTMLInputElement;
            fileInput.click();
        } else {
            this.modalMessage('Solo se pueden configurar maximo de 3 imagenes','warning')
           
        }
    }
    

    changeInputFile (event:any) {
        if ( event.target.files[0]  !== undefined ) {
            this.detailImageFile.push({
                id: this.detailImageFile.length,
                event:event,
                img:  event.target.files[0],
                height: 0,
                uri: ""
            })
            let img = new Image()
            img.src = window.URL.createObjectURL(event.target.files[0])
            const reader = new FileReader();
            img.onload = () => {
                this.detailImageFile[this.detailImageFile.length -1].uri = reader.result;
                this.detailImageFile[this.detailImageFile.length -1].width = img.width
                this.detailImageFile[this.detailImageFile.length -1].height = img.height
            }    
            reader.readAsDataURL(event.target.files[0])
        } 
    }

    removeImage(item) {
        this.detailImageFile = this.detailImageFile.filter(e=> e.id != item.id)
        this.detailImageFile.forEach((e:any, i:number)=>{
            this.detailImageFile[i].id = i
        })
    }


    async saveNewBaner () {
        // Valida la informacion
        if (this.name === "" ) {
            this.modalMessage("Es necesario proporcionar un nombre", "warning")
            return
        } 
        if ( this.categoryBanner.length == 0) {
            this.modalMessage("Es necesario agregar minimo 1 categoria, maximo 3", "warning")
            return
        }
        if ( this.detailImageFile.length == 0) {
            this.modalMessage("Es necesario agregar minimo 1 imagen, maximo 3", "warning")
            return
        }
        this.loader().then((loader) => {
				loader.present().then(async() => {
                    let promise = []
                    let category = []
                    this.detailImageFile.forEach((item) => {
                        promise.push(this.api.pushImageStorage$(item.img, 'banners-dashboard'))
                    })
                    this.categoryBanner.forEach((item) => {
                        category.push({
                            id: item.$key,
                            name: item.name
                        })
                    })
                    promise = await Promise.all(promise)
                    promise.forEach((item:any, i:number)=>{
                        delete promise[i].bucket
                        delete promise[i].contentType
                        delete promise[i].size
                        delete promise[i].updated
                    })
                    let banner = {
                        name: this.name,
                        categoryKeys: category,
                        created_at: new Date(),
                        images: promise,
                        visible: this.visibleStatus,
                        details: [{
                            created_at: new Date(),
                            type: "created",
                            user: this.navParams.get('token'),
                        }]
                    }
                    this.api.addDocument("stores/" + this.storekey + "/banners", banner).then(data => {
                        loader.dismiss();
						this.modalController.dismiss();

                    });
                })
        })
    }

    async addElementRedirect ( ) {
        const modal = await this.modalController.create({
            component: AddReferenceBanerComponent,
            cssClass: 'addModal',
            componentProps: { 
                reference: this.reference,
                store: this.storekey
            }
        });
        
        modal.onDidDismiss().then(data => {
          
        })
        return await modal.present();
    }

    async loader() {
		return await this.loadingCtrl.create({
			spinner: 'lines-small',
			animated: true,
			mode: 'ios',
			translucent: false,
			cssClass: 'custom-class custom-loading',
		});
	}

    modalMessage (message:string, type:SweetAlertIcon) {
        Swal.fire({
            position: 'top-end',
            icon: type,
            title: message,
            showConfirmButton: false,
            timer: 3000,
            toast: true
        });
    }

}
